import React from "react";
import Header from "../components/header";
import Footer from "../components/footer";


function Gallery() {

    return (
        <div style={{}}>
            <Header />
            <h1>Gallery</h1>



            <Footer
                themeColor="#343a40"
                textColor="#f8f9fa"
                buttonBackgroundColor="#007bff"
                buttonColor="#f8f9fa"
            />
        </div>
    );
}

export default Gallery;