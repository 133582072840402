import React, { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ContactUs from "../../components/contact_us";
import { FaPlayCircle } from "react-icons/fa";
import { Col, Container, Row } from "react-bootstrap";

import ReactPlayer from 'react-player/youtube'



export default function AboutUs() {

    const [playing, setPlaying] = useState(false);

    return (
        <div>
            <Header
                themeColor="#003764"
                textColor="#f8f9fa"
            />

            <div style={{ padding: "50px 10px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="video-container mx-auto" >

                    <ReactPlayer
                        id="youtube-video"
                        url='https://www.youtube.com/embed/qtuehIPnnBc?si=qUG7ZZccz-HN1K2H&enablejsapi=1'
                        playing={playing}
                        controls={false}
                    />


                    <button className="overlay"
                        onClick={() => setPlaying(!playing)}
                        style={{ cursor: "pointer", display: playing ? "none" : "block", border: "none", backgroundColor: "#045787" }}
                    >
                        <div className="parent-container" >
                            <h1>
                                Watch Our Story
                            </h1>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <FaPlayCircle className="mx-auto" size={60} style={{ color: "white" }} />
                            </div>
                        </div>
                    </button>
                </div>
            </div>
            <div style={{ backgroundColor: "#045787" }}>
                <Container className=" py-5">
                    <h1 className="text-center" style={{ fontSize: "3em", color: "white" }} >Our Story</h1>
                    <br />
                    <Row>
                        <Col sm={6} md={6} lg={6} className="heroPageText">
                            <div style={{ color: "white" }}>
                                <p>
                                    At Growing People Great, we believe in the extraordinary potential within every individual and team. Our journey began with a simple yet powerful vision: to cultivate environments where people can thrive, grow, and reach their fullest potential.

                                    <br />
                                    <br />
                                    Founded by a team of passionate leaders, coaches, and innovators, Growing People Great was born out of the desire to make a meaningful impact on organisations and the people within them. We recognized that true success is not just about hitting targets or achieving goals—it's about fostering growth, resilience, and well-being in every person.
                                </p>
                            </div>
                        </Col>
                        <Col sm={6} md={6} lg={6} className="heroPageText">
                            <div style={{ color: "white" }}>
                                <p>
                                    At Growing People Great, we are more than just a training and coaching company—we are your partners in growth. We are committed to helping you unlock your potential and achieve greatness, both as individuals and as a team.
                                    <br />
                                    <br />
                                    With this vision in mind, we set out to create a space where leadership is not just about managing but about inspiring and empowering. We understand that every organisation is unique, and so are its people. Join us on this journey of growth, and let’s create a future where everyone can thrive.
                                </p>
                            </div>
                        </Col>
                    </Row>

                </Container>
            </div>

            <Container className="py-5">
                <h1 style={{ fontSize: "3em", color: "#045787" }} className="text-center mb-4">Our Core Values</h1>
                <Row className="justify-content-center" style={{ fontSize: "1.5em " }}>
                    <Col md={6} className="d-flex flex-column align-items-center">
                        <div className="d-flex flex-column" >
                            <p>
                                <span style={{ fontWeight: "bold" }}>
                                    Empowerment
                                </span>
                                <br />
                                We believe in empowering individuals and teams to take ownership of their growth. By providing the right tools, guidance, and support, we help people unlock their potential and achieve their goals.
                            </p>

                        </div>
                    </Col>
                    <Col md={6} className="d-flex flex-column align-items-center">
                        <div className="d-flex flex-column" >
                            <p>
                                <span style={{ fontWeight: "bold" }}>
                                    Integrity
                                </span>
                                <br />
                                We are committed to honesty, transparency, and ethical practices in everything we do. Our integrity is the foundation of the trust we build with our clients, partners, and team members.
                            </p>
                        </div>
                    </Col>
                    <Col md={6} className="d-flex flex-column align-items-center">
                        <div className="d-flex flex-column" >
                            <p>
                                <span style={{ fontWeight: "bold" }}>
                                    Collaboration
                                </span>
                                <br />
                                We thrive on teamwork and the power of collective wisdom. We foster a collaborative environment where diverse ideas and perspectives come together to create innovative solutions.
                            </p>

                        </div>
                    </Col>
                    <Col md={6} className="d-flex flex-column align-items-center">
                        <div className="d-flex flex-column">
                            <p>
                                <span style={{ fontWeight: "bold" }}>
                                    Growth Mindset
                                </span>
                                <br />
                                We embrace change and see challenges as opportunities for growth. We are dedicated to continuous learning and improvement, both for ourselves and for those we work with.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container>
                <ContactUs
                    themeColor="#045787"
                    textColor="#f8f9fa"
                    buttonBackgroundColor="#f8f9fa"
                    buttonColor="#045787"
                />
            </Container>


            <Footer
                themeColor="#045787"
                textColor="#f8f9fa"
            />

        </div >
    );
}