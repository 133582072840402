import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ContactUs from "../../components/contact_us";
import { Row, Col, Card, Container, Button } from "react-bootstrap";
import { FaPlayCircle } from "react-icons/fa";


import genos_logo from "../../assets/logos//genos_logo.png";

import genos_background from "../../assets/genos/genos_background.png";
import genos_grow from "../../assets/genos/genos_grow.png";
import genos_top from "../../assets/genos/genos_top-20.png";
import genos_icf from "../../assets/genos/genos_icf.png";
import genos_grow_card from "../../assets/genos/genos_grow_card.png";
import genos_home from "../../assets/genos/genos_home.png";

import ReactPlayer from 'react-player/youtube'

export default function GenosPage() {
    const navigate = useNavigate();
    const [playing, setPlaying] = useState(false);

    return (
        <div>
            <Header themeColor="rgba(255, 110, 18, 0.81)"
            />

            <div className="text-center" style={{ backgroundColor: "white" }}>
                <div className="text-center" >
                    <Row className="mx-0">
                        <Col md={6} className="px-0">
                            <div className="mx-auto py-3" style={{ maxWidth: "500px" }}>
                                <h2 style={{ color: "#2EA3F2", fontWeight: "bold" }}>
                                    INTRODUCING
                                </h2>
                                <img src={genos_grow} alt="genos_top" style={{ width: "100%" }} />
                                <h5>
                                    An exciting learning platform shaping the future of EI
                                </h5>
                                <p>
                                    We offer a cutting-edge, interactive, personalised, on-demand learning platform that helps
                                    people develop life-changing emotional intelligence skills.
                                </p>
                                <Button size="lg" style={{ backgroundColor: "rgba(255, 110, 18, 0.81)", color: "white" }}
                                    onClick={(() => { navigate('/contact-us') })}
                                >
                                    Contact Us
                                </Button>
                            </div>
                        </Col>
                        <Col md={6} className="px-0">
                            <img src={genos_background} alt="genos_background" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                        </Col>
                    </Row>
                </div>
            </div>

            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#199CD7" }}>
                    <h1 className="mb-0" style={{ color: "white", fontSize: "2em" }}>
                        GROW: Goal, Reality, Obstacles (or Options), and Way Forward
                    </h1>
                </div>
            </div>

            <Container className="py-5">
                <Row >
                    <Col md={6} className="text-center">
                        <h5 style={{ color: "grey", letterSpacing: "3px" }}>AUTHORIZED PARTNER</h5>
                        <img src={genos_logo} alt="genos_logo" style={{ width: "100%", maxWidth: "396px" }} />
                        <Row className="mt-3">
                            <Col xs={6} className="text-end">
                                <img src={genos_top} alt="genos_top" style={{ height: "100%", objectFit: "cover" }} />
                            </Col>
                            <Col xs={6} className="text-start">
                                <img src={genos_icf} alt="genos_icf" style={{ objectFit: "cover", width: "100%", maxWidth: "200px" }} />
                            </Col>
                        </Row>
                    </Col>
                    <Col md={6}>
                        <div className="mx-auto" style={{ textAlign: "center" }}>
                            <h2 style={{ color: "rgba(255, 110, 18, 0.81)", fontWeight: "bold" }}> The Genos Grow Difference</h2>
                            <br />
                            <p style={{ fontSize: "1.4em" }}>
                                Genos Grow is behaviour-
                                based, self-other directed and personalised. Grow People Great is an authorised
                                partner of Genos Grow plus assessments and reports to help your
                                organisation’s people grow their emotional intelligence.
                            </p>
                        </div>
                    </Col>
                </Row>
            </Container>

            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#199CD7" }}>
                    <h1 className="mb-0" style={{ color: "white", fontSize: "2em" }}>
                        The Widest Range of Modern EI Assessments on the Market
                    </h1>
                </div>
            </div>

            <div className="py-5" style={{ backgroundColor: "#D1EBFF" }}>
                <Container>
                    <Row className="justify-content-centre">
                        <Col md={6}>
                            <div className="video-container mx-auto" >

                                <ReactPlayer
                                    id="youtube-video"
                                    url='https://www.youtube.com/watch?v=0fMKnTBM6t0&t=1s'
                                    playing={playing}
                                    controls={false}
                                />


                                <button className="overlay"
                                    onClick={() => setPlaying(!playing)}
                                    style={{ cursor: "pointer", display: playing ? "none" : "block", border: "none", backgroundColor: "#FF6E12" }}
                                >
                                    <div className="parent-container" >
                                        <h1>
                                            How Genos Grow Works
                                        </h1>
                                        <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                            <FaPlayCircle className="mx-auto" size={60} style={{ color: "white" }} />
                                        </div>
                                    </div>
                                </button>
                            </div>
                        </Col>
                        <Col md={6}>
                            <div className="mx-auto px-2" style={{ textAlign: "left" }}>
                                <h4>
                                    With Genos Grow the possibilities are limitless:
                                </h4>
                                <br />
                                <ul>
                                    <li>
                                        Deepen and accelerate the development of emotionally
                                        intelligent behaviours.
                                    </li>
                                    <li>
                                        Enhance wellbeing, communication, and collaboration.
                                    </li>
                                    <li>
                                        Create happy, successful, and psychologically safe
                                        workplaces that attract top talent.
                                    </li>
                                    <li>
                                        Extend EI development beyond training sessions with on-
                                        demand, personalised learning.
                                    </li>

                                </ul>
                                <Button size="lg" style={{ backgroundColor: "#FF6E12", color: "white", }}
                                    onClick={(() => { navigate('/contact-us') })}
                                >
                                    Contact Us
                                </Button>
                            </div>
                        </Col>
                    </Row>
                    <Row className="py-5">
                        <Col md={12}>
                            <Row className="mb-3 mx-0">
                                <Col md={4} className="mb-3 mb-md-0">
                                    <Row className="mb-3">
                                        <Card className="p-3">
                                            <Card.Title className="text-center">
                                                <h4>Personalise</h4>
                                            </Card.Title>
                                            <Card.Body>
                                                <p style={{ color: "#003764", fontWeight: "" }}>
                                                    Users select behaviours they’d like to improve, establishing ownership from the outset.
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </Row>
                                    <Row>
                                        <Card className="p-3">
                                            <Card.Title className="text-center">
                                                <h4>Nurture</h4>
                                            </Card.Title>
                                            <Card.Body>
                                                <p>
                                                    Enable users to access virtual group or one-one coaching, by appointing an expert coach or facilitator.
                                                </p>
                                            </Card.Body>
                                        </Card>
                                    </Row>
                                </Col>
                                <Col md={8} className="px-sm-0 px-md-3" >
                                    <Card className="p-3" style={{ height: "100%" }} >
                                        <Row style={{ height: "100%" }}>
                                            <Col md={5}>
                                                <Card.Title className="text-center">
                                                    <h4>Plan</h4>
                                                </Card.Title>
                                                <Card.Body>
                                                    <p>
                                                        Expertly curated content and developmental insights help users create a personalised plan to enhance their selected behaviours.
                                                    </p>
                                                </Card.Body>
                                            </Col>
                                            <Col md={7}>
                                                <img src={genos_home} alt="genos_plan" style={{ width: "100%", height: "100%", objectFit: "contain" }} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                            </Row>
                            <Row>
                                <Col md={8} className="mb-3 mb-md-0">
                                    <Card className="py-3" style={{ height: "100%" }}>
                                        <Row className="mx-0" style={{ height: "100%" }}>
                                            <Col md={6}>
                                                <Card.Title className="text-center">
                                                    <h4>Grow</h4>
                                                </Card.Title>
                                                <Card.Body>
                                                    <p>
                                                        Drive behaviour change through integrated nudges, and maintain engagement with inspiring resources, meditations, and videos.
                                                    </p>
                                                </Card.Body>
                                            </Col>
                                            <Col md={6}>
                                                <img src={genos_grow_card} alt="genos_grow_card" style={{ width: "100%", height: "100%", maxHeight: "200px", objectFit: "cover" }} />
                                            </Col>
                                        </Row>
                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card className="p-3" style={{ height: "100%" }}>
                                        <Card.Title className="text-center">
                                            <h4>Measure</h4>
                                        </Card.Title>
                                        <Card.Body>
                                            <p>
                                                Drive behaviour change through integrated nudges, and maintain engagement with inspiring resources, meditations, and videos.
                                            </p>
                                        </Card.Body>
                                    </Card>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#199CD7" }}>
                    <h1 className="mb-0" style={{ color: "white" }}>
                        Emotional Intelligence Assessment Reports
                    </h1>
                </div>
            </div>

            <div className="py-5">
                <Container>
                    <Row >
                        <Card className="p-4 mx-auto mb-3" style={{ borderRadius: "30px", rotate: "180px", maxWidth: "500px" }}>
                            <Card.Title className="">
                                Workplace Development Reports
                            </Card.Title>
                            <Card.Body>
                                <ul>
                                    <li>
                                        Emotional Intelligence Selection Report
                                    </li>
                                    <li>
                                        Emotional Intelligence Workplace Behaviour Self Report
                                    </li>
                                    <li>
                                        180 Emotional Intelligence Workplace
                                    </li>
                                    <li>
                                        Behaviour Feedback Report 360 Emotional Intelligence Workplace Behaviour Feedback Report
                                    </li>
                                </ul>
                            </Card.Body>
                            <Button size="lg" style={{ backgroundColor: "#199CD7", color: "white" }}
                                onClick={(() => { navigate('/services/genos-assessment') })}
                            >
                                Get Started
                            </Button>
                        </Card>
                    </Row>
                </Container>
            </div>

            <Container>
                <ContactUs
                    themeColor="#199CD7"
                    textColor="#f8f9fa"
                    buttonBackgroundColor="white"
                    buttonColor="#199CD7"
                />
            </Container>

            <Footer
                themeColor="#199CD7"
                textColor="#f8f9fa"
                buttonBackgroundColor="#007bff"
                buttonColor="#f8f9fa"
            />
        </div >
    );
}