import React, { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Container, Row, Col, Card, Button, Modal } from "react-bootstrap";
import ContactUs from "../../components/contact_us";
import { HiOutlineDesktopComputer } from "react-icons/hi";
import { TiDocumentText } from "react-icons/ti";
import { FaChalkboardTeacher, FaPlayCircle } from "react-icons/fa";
import { CgArrowsExchange } from "react-icons/cg";
import { useSpring, animated } from "@react-spring/web";

// images
import disc_logo from "../../assets/logos/everythingDisc_logo.png";
import everythingDiscHeroImage from "../../assets/everything_disc/everythingDiscHeroImage.png";

// import yourDiscStyle from "../../assets/everything_disc/yourDiscStyle.png"
// import yourollegues from "../../assets/everything_disc/yourCollegues.png"
// import yourGroups from "../../assets/everything_disc/yourGroups.png"
// import accessTheirDiscApplication from "../../assets/everything_disc/accessTheirDiscApplication.png"
// import yourDiscProfileReport from "../../assets/everything_disc/yourDiscProfileReport.png"

// SVG LOGO 
import workplaceLogo from "../../assets/everything_disc/workplaceLogo.svg";
import managementLogo from "../../assets/everything_disc/managementLogo.svg";
import workLeadersLogo from "../../assets/everything_disc/workLeaders.svg";
import agileEQLogo from "../../assets/everything_disc/agileEQLogo.svg";
import productiveConflictLogo from "../../assets/everything_disc/productiveConflictLogo.svg";
import salesLogo from "../../assets/everything_disc/salesLogo.svg";

import { useNavigate } from "react-router-dom";

import ReactPlayer from 'react-player/youtube'


// const data = [
//     {
//         title: "Your Disc Style",
//         description: "Get personalised insights around your unique personality, behavioural preferences and tendencies.",
//         image: yourDiscStyle
//     },
//     {
//         title: "Your Colleagues",
//         description: "Get personalised insights around your unique personality, behavioural preferences and tendencies.",
//         image: yourollegues
//     },
//     {
//         title: "Your Groups",
//         description: "Get personalised insights around your unique personality, behavioural preferences and tendencies.",
//         image: yourGroups
//     },
//     {
//         title: "Access Their Disc Application",
//         description: "Get personalised insights around your unique personality, behavioural preferences and tendencies.",
//         image: accessTheirDiscApplication
//     },
//     {
//         title: "Your Disc Profile Report",
//         description: "Get personalised insights around your unique personality, behavioural preferences and tendencies.",
//         image: yourDiscProfileReport
//     }
// ]


const cardData = [
    {
        id: 1,
        title: "Everything DiSC Workplace",
        text: "Build Better Relationships",
        logo: workplaceLogo,
        modelTile: "Engage every individual in building more effective relationships at work.",
        details: "Details about Everything DiSC Workplace...",
        bulletPoints: [
            "In-depth self-discovery",
            "Appreciation for differences in work styles",
            "Strategies for successful interactions",
        ]
    },
    {
        id: 2,
        title: "Everything DiSC Management",
        text: "Enhance Management Skills",
        logo: managementLogo,
        modelTile: "Teach managers to successfully engage, motivate, and develop their people.",
        details: "Details about Everything DiSC Management...",
        bulletPoints: [
            "Discovery of personal management style",
            "Insight into directing, delegating, and motivating",
            "Strategies to support long-term development",
        ]
    },
    {
        id: 3,
        title: "Everything DiSC Agile EQ",
        text: "Develop Emotional Intelligence",
        logo: agileEQLogo,
        modelTile: "Develop the emotional intelligence necessary to support a thriving agile culture.",
        details: "Details about Everything DiSC Agile EQ...",
        bulletPoints: [
            "Discover your EQ strengths",
            "Recognize your EQ potential",
            "Commit to customized strategies for building agility",
        ]
    }
];

const dataOther = [
    {
        id: 4,
        title: "Everything DiSC Sales",
        text: "Improve Sales Effectiveness",
        logo: salesLogo,
        modelTile: "Provide salespeople with the skills to adapt to customers’ preferences and expectations.",
        details: "Details about Everything DiSC Sales...",
        bulletPoints: [
            "Discovery of personal sales style",
            "Recognition of customers’ differences",
            "Strategies to adapt sales approaches to customer needs",
        ]
    },
    {
        id: 5,
        title: "Everything DiSC Productive Conflict",
        text: "Transform Conflict into Collaboration",
        logo: productiveConflictLogo,
        modelTile: "Harness the power of conflict by transforming destructive behavior into productive responses.",
        details: "Details about Everything DiSC Productive Conflict...",
        bulletPoints: [
            "Self-awareness around reactions to conflict",
            "The impact different conflict responses have on others",
            "Steps to change responses from destructive to productive",
        ]
    },
    {
        id: 6,
        title: "Everything DiSC Work of Leaders",
        text: "Comprehensive Leadership Development",
        logo: workLeadersLogo,
        modelTile: "Create impactful leaders through the process of Vision, Alignment, and Execution.",
        details: "Details about Everything DiSC 363 for Leaders...",
        bulletPoints: [
            "A simple, compelling model of leadership",
            "Personalised insights to leverage strengths and overcome challenges",
            "A clear path for improvement",
        ]
    }
];


export default function EverythingDisc() {

    const navigate = useNavigate();

    function Number({ number }) {
        const { numberValue } = useSpring({
            from: { numberValue: 0 },
            numberValue: number,
            delay: 200,
            config: {
                duration: 2000
            }
        });

        return <animated.span>{numberValue.to((n) => n.toFixed(0))}</animated.span>;
    }

    const [showModal, setShowModal] = useState(null);

    const handleModal = (id) => {
        setShowModal(id);
    };

    const [playing, setPlaying] = useState(false);

    return (
        <div>
            <Header themeColor="#0094D0"
            />

            <div className="everythingDisc" style={{ backgroundColor: "EDF8FF" }}>
                <img className="heroImage" src={everythingDiscHeroImage} alt="Man laughing"
                    style={{ width: "100%", height: "100%", objectFit: "cover" }}
                />
                <div className="overlay" style={{
                    top: "15%",
                    left: 0,
                    width: "100%",
                    height: "100%",
                    display: "flex",
                    justifyContent: "start",
                    alignItems: "start",
                }}>
                    <Container>
                        <div className="everythingDiscHeroText" style={{ color: "", textAlign: "left" }}>
                            <h1 style={{ color: "#003764", fontWeight: "bold" }}>
                                Top-Tier Coaching and Development
                            </h1>
                            <p>
                                Catalyst™ is a personalised learning platform that acts as each learner’s home base throughout their DiSC® journey.
                            </p>
                            <Button style={{ backgroundColor: "#F3CC23", width: "40%" }} variant="primary" onClick={() => navigate("/contact-us")}>Contact Us</Button>
                            <img className="everythingDiscHeroImage" src={disc_logo} alt="EverythingDisc Logo" />
                        </div>
                    </Container>
                </div>
            </div >
            <img className="heroImageAdjust" src={everythingDiscHeroImage} alt="Man laughing"
                style={{ width: "100%", height: "100%", objectFit: "cover" }}
            />
            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#0094D0" }}>
                    <h1 className="mb-0" style={{ color: "white" }}>
                        What Is Culture Catalyst™?
                    </h1>
                </div>
            </div>
            <div style={{ backgroundColor: "#EDF8FF" }}>
                <Container>
                    <Row className="py-5">
                        <Col sm={12} md={7} lg={7} className="mx-auto">
                            <p className="text-center" style={{ fontSize: "1.2em", color: "#003764" }}>
                                Everything DiSC uses the DiSC® model's four styles <span style={{ fontWeight: "bold" }}> (Dominance, influence, Steadiness, Conscientiousness) </span> to boost self-awareness and improve workplace interactions, fostering a more engaged and collaborative team while enhancing your organisation's culture.
                            </p>
                        </Col>
                    </Row>
                    <div className="video-container mx-auto" >

                        <ReactPlayer
                            id="youtube-video"
                            url='https://https://youtu.be/yYWp7oKV4yA'
                            playing={playing}
                            controls={false}
                        />


                        <button className="overlay"
                            onClick={() => setPlaying(!playing)}
                            style={{ cursor: "pointer", display: playing ? "none" : "block", border: "none" }}
                        >
                            <div className="parent-container" >
                                <h1>
                                    ALL ABOUT THE CULTURE CATALYST™
                                </h1>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <FaPlayCircle className="mx-auto" size={60} style={{ color: "white" }} />
                                </div>
                            </div>
                        </button>
                    </div>

                    <Row className="py-5">
                        <Col sm={6} md={6} lg={3} className="mb-3">
                            <Card className="p-2 mb-3 mb-md-0 rounded" style={{ textAlign: "center", height: "100%" }}>
                                <div className="mb-3 text-centre rounded-circle p-2 mx-auto" style={{ backgroundColor: "#5FB888", width: "fit-content" }}>
                                    <HiOutlineDesktopComputer size={50} style={{ fontSize: "2rem", color: "white" }} />
                                </div>

                                <div className="text-centre" style={{ color: "#5FB888" }}>
                                    <h4 style={{ fontWeight: "bold" }}>Highly Personalised</h4>
                                </div>
                                <p>
                                    90% accuracy rating using its scientifically validated and reliable computer-adaptive assessment.
                                </p>
                                <div className="mt-auto" style={{ backgroundColor: "#5FB888", color: "white", height: "20px" }}>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={6} md={6} lg={3} className="mb-3">
                            <Card className="p-2 mb-3 mb-md-0 rounded" style={{ textAlign: "center", height: "100%" }}>
                                <div className="mb-3 text-centre rounded-circle p-2 mx-auto" style={{ backgroundColor: "#CD3741", width: "fit-content" }}>
                                    <TiDocumentText size={50} style={{ fontSize: "2rem", color: "white" }} />
                                </div>

                                <div className="text-centre" style={{ color: "#CD3741" }}>
                                    <h4 style={{ fontWeight: "bold" }}>Applied in Real Time</h4>
                                </div>
                                <p>
                                    Flexible facilitation ensures that personalised insights are easily integrated into daily work life.
                                </p>
                                <div className="mt-auto" style={{ backgroundColor: "#CD3741", color: "white", height: "20px" }}>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={6} md={6} lg={3} className="mb-3">
                            <Card className="p-2 mb-3 mb-md-0 rounded" style={{ textAlign: "center", height: "100%" }}>
                                <div className="mb-3 text-centre rounded-circle p-2 mx-auto" style={{ backgroundColor: "#00A1D1", width: "fit-content" }}>
                                    <FaChalkboardTeacher size={50} style={{ fontSize: "2rem", color: "white" }} />
                                </div>

                                <div className="text-centre" style={{ color: "#00A1D1" }}>
                                    <h4 style={{ fontWeight: "bold" }}>Guided by an Expert</h4>
                                </div>
                                <p>
                                    Connect with our expert coaches for personalised solutions tailored to the unique needs of individuals and organisations.
                                </p>
                                <div className="mt-auto" style={{ backgroundColor: "#00A1D1", color: "white", height: "20px" }}>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={6} md={6} lg={3} className="mb-3">
                            <Card className="p-2 mb-3 mb-md-0 rounded" style={{ textAlign: "center", height: "100%" }}>
                                <div className="mb-3 text-centre rounded-circle p-2 mx-auto" style={{ backgroundColor: "#F3CC23", width: "fit-content" }}>
                                    <CgArrowsExchange size={50} style={{ fontSize: "2rem", color: "white" }} />
                                </div>

                                <div className="text-centre" style={{ color: "#F3CC23" }}>
                                    <h4 style={{ fontWeight: "bold" }}>Proven Satisfaction</h4>
                                </div>
                                <p>
                                    Over 10million people in over 150, 000+ organisations impacted. Available in 22 languages with a 97% satisfaction rate.
                                </p>
                                <div className="mt-auto" style={{ backgroundColor: "#F3CC23", color: "white", height: "20px" }}>
                                </div>
                            </Card>
                        </Col>

                    </Row>


                </Container>
            </div>
            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#0094D0" }}>
                    <h1 className="mb-0" style={{ color: "white" }}>
                        Catalyst Learning Experience - Application Suite
                    </h1>
                </div>
            </div>
            <Container className="py-5">
                <Row>
                    {cardData.map(card => (
                        <Col key={card.id} sm={6} md={4} lg={4} className="mb-3">
                            <Card className="p-4" style={{ height: "100%", maxWidth: "350px", marginLeft: "auto", marginRight: "auto" }}>
                                <Row>
                                    <Col sm="auto">
                                        <Card.Img variant="top" src={card.logo}
                                            style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                        />
                                    </Col>
                                    <Col className="text-right">
                                        <h5 style={{ color: "#003764", fontWeight: "bold" }}>{card.title}</h5>
                                    </Col>
                                </Row>
                                <Card.Body>
                                    <Card.Text>
                                        {card.text}
                                    </Card.Text>
                                </Card.Body>
                                <Button style={{ backgroundColor: "#F3CC23" }} variant="primary" onClick={() => handleModal(card.id)}>See More</Button>
                            </Card>

                            <Modal show={showModal === card.id} onHide={() => setShowModal(null)} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>{card.title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h6>{card.modelTile}</h6>
                                    <ul>
                                        {card.bulletPoints.map((point, index) => (
                                            <li key={index}>{point}</li>
                                        ))}
                                    </ul>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowModal(null)}>Close</Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    ))}
                </Row>
            </Container>

            <h1 className="text-center">
                Additional Everything DiSC Applications
            </h1>

            <Container className="py-5">
                <Row>
                    {dataOther.map(card => (
                        <Col key={card.id} sm={6} md={4} lg={4} className="mb-3">
                            <Card className="p-4" style={{ height: "100%", maxWidth: "350px", marginLeft: "auto", marginRight: "auto" }}>
                                <Row>
                                    <Col sm="auto">
                                        <Card.Img variant="top" src={card.logo}
                                            style={{ width: "50px", height: "50px", objectFit: "cover" }}
                                        />
                                    </Col>
                                    <Col className="text-right">
                                        <h5 style={{ color: "#003764", fontWeight: "bold" }}>{card.title}</h5>
                                    </Col>
                                </Row>
                                <Card.Body>
                                    <Card.Text>
                                        {card.text}
                                    </Card.Text>
                                </Card.Body>
                                <Button style={{ backgroundColor: "#F3CC23" }} variant="primary" onClick={() => handleModal(card.id)}>See More</Button>
                            </Card>

                            <Modal show={showModal === card.id} onHide={() => setShowModal(null)} centered>
                                <Modal.Header closeButton>
                                    <Modal.Title>{card.title}</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                    <h6>{card.modelTile}</h6>
                                    <ul>
                                        {card.bulletPoints.map((point, index) => (
                                            <li key={index}>{point}</li>
                                        ))}
                                    </ul>
                                </Modal.Body>
                                <Modal.Footer>
                                    <Button variant="secondary" onClick={() => setShowModal(null)}>Close</Button>
                                </Modal.Footer>
                            </Modal>
                        </Col>
                    ))}
                </Row>
            </Container>

            <div>
                <div className="text-center py-2">
                    <h1 className="mb-0">
                        On the Catalyst platform, you'll have access to:
                    </h1>
                </div>
            </div>

            <Container className="py-5">
                <Row>
                    <Col sm={12} md={12} lg={4} className="mx-auto">
                        <ul style={{ fontSize: "1.5em" }}>
                            <li>
                                Your Disc Style
                            </li>
                            <li>Your Colleagues</li>
                            <li>Your Groups</li>
                            <li>Access Their Disc Application</li>
                            <li>Your Disc Profile Report</li>
                        </ul>
                    </Col>
                </Row>

                <Row className="pt-5">
                    <Col sm={12} md={12} lg={4} className=" mx-auto" >
                        <h2 style={{ color: "#00B67A", fontWeight: "bold", fontSize: "4em" }}>
                            <Number number={7000000} />
                            +
                        </h2>
                        <p style={{ fontWeight: "bold" }}>Have been helped by EverythingDisc</p>
                        <h2 style={{ color: "#2EA3F2", fontWeight: "bold", fontSize: "4em" }}>
                            <Number number={10000} />
                            +
                        </h2>
                        <p style={{ fontWeight: "bold" }}>Organisation helped</p>
                        <h2 style={{ color: "#F90E0E", fontWeight: "bold", fontSize: "4em" }}>
                            <Number number={20} />
                            +</h2>
                        <p style={{ fontWeight: "bold" }}>Countries impacted in 13 languages</p>
                    </Col>
                </Row>

            </Container>


            <Container>
                <ContactUs

                    themeColor="#199CD7"
                    textColor="#f8f9fa"
                    buttonBackgroundColor="white"
                    buttonColor="#199CD7"
                />

            </Container>


            <Footer
                themeColor="#199CD7"
            />
        </div >
    );
}