import React, { useState } from "react";
import { Navbar, Nav, NavDropdown, Button } from "react-bootstrap";

// Logo
import logo from "../assets/growingpeoplegreat_logo.png";

//CSS
import "../styles/components.css"
import { useNavigate } from "react-router-dom";

function Header(props) {
    const navigate = useNavigate();
    const [isHovered, setIsHovered] = useState(false);

    return (
        <Navbar expand="lg">
            <Navbar.Brand onClick={() => navigate("/")}>

                <div className="" style={{ textAlign: "center" }} >
                    <img
                        alt=""
                        src={logo}
                        width="50"
                        object-fit="cover"

                        className="d-inline-block align-top"
                    />{' '}
                    <h6>
                        <span style={{ color: "#4aadd7" }}>
                            growing
                        </span>
                        people
                        <span style={{ color: "#4aadd7" }}>
                            great
                        </span>


                    </h6>
                </div>
            </Navbar.Brand>
            <Navbar.Toggle />
            <Navbar.Collapse className="justify-content-end">
                <Nav className="mr-auto">
                    <Nav.Link onClick={() => navigate("/")} >Home</Nav.Link>
                    <NavDropdown title="Services" id="collasible-nav-dropdown">
                        <NavDropdown.Item onClick={() => navigate("/services/everything-disc")}>Everything Disc</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => navigate("/services/five-behaviors")}>Five Behaviors</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => navigate("/services/pxt-select")}>PXT Select</NavDropdown.Item>
                        <NavDropdown.Header>Genos</NavDropdown.Header>
                        <NavDropdown.Item onClick={() => navigate("/services/genos")}>Emotional Intelligence</NavDropdown.Item>
                        <NavDropdown.Item onClick={() => navigate("/services/genos-assessment")}>Leadership</NavDropdown.Item>

                    </NavDropdown>
                    <Nav.Link onClick={() => navigate("/about-us")}>Our Story</Nav.Link>
                    {/* <Nav.Link onClick={() => navigate("/gallery")}>Gallery</Nav.Link> */}
                </Nav>
                <Button
                    className="contactUsBtn"
                    style={{
                        borderColor: props.themeColor,
                        backgroundColor: isHovered ? props.themeColor : "white",
                        color: isHovered ? "white" : props.themeColor,
                    }}
                    onClick={() => navigate("/contact-us")}
                    onMouseEnter={() => {
                        setIsHovered(true);

                    }}
                    onMouseLeave={() => setIsHovered(false)}
                >
                    Contact Us
                </Button>
            </Navbar.Collapse>
        </Navbar >
    );
}

export default Header;