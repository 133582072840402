import React from "react";
import { Row, Container } from "react-bootstrap";
import "../styles/components.css";
import { FaArrowRightLong } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";

function SliderHeroPage(props) {
    const navigate = useNavigate();
    return (
        <Container>
            <Row className=" py-5 align-items-center" >
                <div className="heroPageText">
                    <h1 style={{ fontSize: "2.7em" }}> {props.title}</h1>
                    <br />
                    <p>
                        {props.description}
                    </p>
                    <p>
                        {props.subtitle}
                    </p>
                    <div>
                        <button className="btn mt-2 homeBtn"
                            onClick={() => navigate(props.link)}
                        >Get Started &nbsp;&nbsp;&nbsp; <FaArrowRightLong /></button>
                    </div>
                </div>

                {/* <Col sm={12} md={6} className="mb-3 pr-3">

                    <h1 style={{ color: "white" }}> {props.title}</h1>
                    <p>
                        {props.description}
                    </p>
                    <p>
                        {props.subtitle}
                    </p>
                    <button className="btn mt-5 homeBtn"
                        onClick={() => navigate(props.link)}
                    >Get Started &nbsp;&nbsp;&nbsp; <FaArrowRightLong /></button>
                </Col>
                <Col>
                    <img src={props.image} alt="Coaching" className="coaching_image rounded" style={{ width: "100%", objectFit: "cover" }} />
                </Col> */}
            </Row>
        </Container>
    );
}

export default SliderHeroPage;