import { Route, BrowserRouter, Routes } from "react-router-dom";
import 'bootstrap/dist/css/bootstrap.min.css';

import ScrollToTop from "./components/scrollToTop";

import HomePage from "./pages/home_page";
import ContactUs from "./pages/contact_us_page";
import Gallery from "./pages/gallery_page";
import PxtSelectPage from "./pages/services/pxt_select_page";
import GenosPage from "./pages/services/genos_page";
import FiveBehaviors from "./pages/services/five_behaviors";
import EverythingDisc from "./pages/services/everything_disc";
import AboutUs from "./pages/services/about_us";
import GenosAssessmentPage from "./pages/services/genos_assessment_page";


function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ScrollToTop />
        <Routes>
          <Route path="/" element={<HomePage />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route path="/services/pxt-select" element={<PxtSelectPage />} />
          <Route path="/services/genos" element={<GenosPage />} />
          <Route path="/services/genos-assessment" element={<GenosAssessmentPage />} />
          <Route path="/services/five-behaviors" element={<FiveBehaviors />} />
          <Route path="/services/everything-disc" element={<EverythingDisc />} />
          <Route path="/about-us" element={<AboutUs />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
