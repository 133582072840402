import React from "react";
import { Row, Col, Container } from "react-bootstrap";

// Logo
// import logo from "../assets/growingpeoplegreat_logo.png";

//CSS
import "../styles/components.css"
import { FaSquareFacebook, FaLinkedinIn, FaXTwitter } from "react-icons/fa6";
import { useNavigate } from "react-router-dom";


const year = new Date().getFullYear();

function Footer(props) {
    const navigate = useNavigate();
    return (
        <div className="footer py-4" style={{ backgroundColor: props.themeColor }}>

            <Container>

                {/* <Row className="align-items-start">

                    <Col> */}
                <Row className="align-items-centre justify-content-center">


                    <Col sm={3} className="justify-content-centre mb-3 mb-sm-0">

                        <h3>
                            Quick Links
                        </h3>
                        <a href="/" onClick={() => { navigate('/') }}>
                            Home</a>
                        <br />
                        <a href="/about-us"
                            onClick={() => { navigate('/about-us') }}
                        >
                            About Us</a>
                        <br />
                        <a href="/contact-us" onClick={() => { navigate('/contact-us') }}

                        >Contact Us</a>

                    </Col>
                    <Col sm={3} className="justify-content-centre mb-3 mb-sm-0">

                        <h3>Products & Services</h3>


                        <a href="/services/everything-disc" onClick={() => { navigate('/services/everything-disc') }}>Everything Disc</a>
                        <br />
                        <a href="/services/five-behaviors" onClick={() => { navigate('/services/five-behaviors') }}>Five Behaviors</a>
                        <br />
                        <a href="/services/genos" onClick={() => { navigate('/services/genos') }}>Genos</a>
                        <br />
                        <a href="/services/pxt-select" onClick={() => { navigate('/services/pxt-select') }}
                        >PXT Select</a>



                    </Col>
                    <Col sm={3} >
                        <h3>Offices</h3>
                        <address>
                            76 Emolweni Road,<br />
                            Kloof, 3610, Durban
                            <br />South Africa
                            <br />

                            Phone: +27 31 944 3060
                            <br />
                            <br />
                            4 Cheyney Road,<br />
                            Lone Hill Sandton, 2062,
                            <br />South Africa
                            <br />

                            Phone: +27 10 447 1958
                            {/* <br />
                            <br /> */}
                            {/* Email:
                            <a href="mailto:info@growingpeoplegreat.com">info@growingpeoplegreat.com</a> */}

                        </address>
                    </Col>
                </Row>
                {/* </Col> */}

                {/* <Col sm="auto" className="pr-5 " >
                        <Row className="justify-content-end align-items-end ">
                            <img className="footer_logo" src={logo} alt="Growing People Great" />
                        </Row>
                    </Col> */}
                {/* </Row> */}
                <hr />
                <p className="text-center">© {year} GrowingPeopleGreat</p>
                <div className="text-center">
                    <a className="mx-2" href="https://www.facebook.com/Growing-People-Great-975547719170651/" target="_blank" rel="noreferrer">
                        <FaSquareFacebook size={25} />
                    </a>
                    <a className="mx-2" href="https://za.linkedin.com/company/profiles-international-southern-africa" target="_blank" rel="noreferrer">
                        <FaLinkedinIn size={25} />
                    </a>
                    <a href="http://www.twitter.com/growpeoplegr8" target="_blank" rel="noreferrer">
                        <FaXTwitter size={25} />
                    </a>
                </div>

            </Container>


        </div >
    );
}

export default Footer;