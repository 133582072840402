import React from "react";
import { useNavigate } from "react-router-dom";
import { Card, Button } from "react-bootstrap";
import { FaArrowRightLong } from "react-icons/fa6";

function ServicesCard(props) {
    const navigate = useNavigate();
    return (
        <div>
            <Card className="rounded p-3">
                <Card.Body>
                    <Card.Title>{props.title}</Card.Title>
                    <Card.Text>
                        {props.description}
                    </Card.Text>
                    <div className="d-flex justify-content-end">
                        <Button size="lg" style={{ backgroundColor: props.themeColor }} href={props.link}
                            onClick={() => navigate(props.link)}
                        >
                            See more &nbsp;&nbsp;&nbsp;&nbsp;
                            <FaArrowRightLong />
                        </Button>
                    </div>
                </Card.Body>

            </Card>
        </div>
    );
}
export default ServicesCard;