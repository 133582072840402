import React, { useState } from "react";
import Header from "../../components/header";
import { useNavigate } from "react-router-dom";

import { Col, Container, Row, Card, Button } from "react-bootstrap";
import { IoDocumentTextOutline, IoChatbubblesOutline } from "react-icons/io5";
import { TbUserSearch } from "react-icons/tb";
import { GoPeople } from "react-icons/go";
import { FaRegCircleCheck, FaBuildingColumns } from "react-icons/fa6";
import { FaTrophy, FaPlayCircle } from "react-icons/fa";
import { HiMiniCheckBadge } from "react-icons/hi2";
import { IoIosPeople } from "react-icons/io";
import { GiNotebook } from "react-icons/gi";
import { RiComputerLine } from "react-icons/ri";

// import pxtSelect_logo from "../assets/logos/pxtSelect_logo.png";
import checkPoint_image from "../../assets/pxt_select/checkPoint360.png";
import customer_service_profile_image from "../../assets/pxt_select/customer_service_profile.png";
import stepOne_survey_image from "../../assets/pxt_select//step_one_survey.png";
import pxt_strategic_workforce_planning from "../../assets/pxt_select/PXT-Photos-04.jpg";
import pxt_replicate from "../../assets/pxt_select/PXT-Photos-01.jpg";
import pxt_select_service from "../../assets/pxt_select/pxt_select_service.png";

//Documents
import pxt_brochure_cp360 from "../../assets/Documents/pxt-brochure-cp360.pdf";
import pxt_brochure_csp_pgp from "../../assets/Documents/pxt-brochure-csp-pgp.pdf";
import pxt_brochure_sos from "../../assets/Documents/pxt-brochure-sos.pdf";
import pxt_brochure from "../../assets/Documents/pxt-brochure.pdf";

import Footer from "../../components/footer";
import ContactUs from "../../components/contact_us";

import ReactPlayer from 'react-player/youtube'


function PxtSelectPage() {
    const navigate = useNavigate();
    const [playing, setPlaying] = useState(false);

    return (
        <div>
            <Header
                themeColor="#20A7AA"
            />

            <div className="text-left">
                <Container className="py-5">

                    <Row className="align-items-center">
                        <Col sm={12} md={6} className="mb-2">
                            <h1 style={{ color: "#20A7AA", fontWeight: "bold" }}>
                                PXT Select™
                            </h1>
                            <p className="mt-3">
                                PXT Select™ helps make the very human decisions about hiring simpler and smarter.
                                It is an online selection assessment that measures a candidate’s cognitive abilities, behaviors, and interests.
                                This assessment provides organisations with easy-to-understand information about candidates, and it provides interview questions to help you
                                identify the best candidate for the job.
                            </p>
                            <Button size="lg" href="/contact-us" onClick={() => navigate("/contact-us")} style={{ backgroundColor: "#136D58", color: "white" }}>
                                Contact Us
                            </Button>
                        </Col>
                        <Col sm={12} md={6}>
                            <img
                                className="rounded"
                                style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                src={pxt_replicate} alt="Replicate Top Performers" />
                        </Col>
                    </Row>
                </Container>
            </div>
            <div style={{ backgroundColor: "#D6E4E2" }}>
                <Container className="py-5 text-centre">


                    <div className="video-container mx-auto" >

                        <ReactPlayer
                            id="youtube-video"
                            url='https://www.youtube.com/embed/guXDLqu2urQ?si=EUX-yxytGknk_ZU7'
                            playing={playing}
                            controls={false}
                        />


                        <button className="overlay"
                            onClick={() => setPlaying(!playing)}
                            style={{ cursor: "pointer", display: playing ? "none" : "block", backgroundColor: "#136D58", border: "none" }}
                        >
                            <div className="parent-container" >
                                <h1>
                                    HOW IT WORKS
                                </h1>
                                <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                    <FaPlayCircle className="mx-auto" size={60} style={{ color: "white" }} />
                                </div>
                            </div>
                        </button>
                    </div>


                    <Row className="pt-3 text-centre justify-content-center">
                        <div style={{ textAlign: "centre" }}>
                            <h1 className="px-5 pt-3 text-center" style={{ color: "#20A7AA" }}
                            >One Assessment.<br />
                                Four Simple Steps. <br />
                                Easily build the
                                best team!
                            </h1>
                        </div>
                        <Col sm={6} md={5} lg={3} className="mb-2">
                            <Card className="p-2 mb-3 mb-md-0 rounded" style={{ textAlign: "center", height: "100%" }}>
                                <div className="text-centre" style={{ color: "#5FB888" }}>
                                    <h3 style={{ fontSize: "3rem ", fontWeight: "bold" }}>1</h3>
                                    <h3 style={{ fontWeight: "bold" }}>PLAN</h3>
                                    <h5>Know the job</h5>
                                </div>
                                <div style={{ backgroundImage: "linear-gradient(to bottom, white  50%,  #5FB888  50%)", }} >
                                    <div className="rounded-circle p-1 mx-auto" style={{ backgroundColor: "#5FB888", width: "fit-content", border: "2px Solid white" }}>
                                        < IoDocumentTextOutline size={40} style={{ fontSize: "2rem", color: "white" }} />
                                    </div>
                                </div>
                                <div className="p-3" style={{ backgroundColor: "#5FB888", color: "white", height: "100%" }}>
                                    <p>
                                        Breadth of performance models helps hiring managers and recruiters
                                        align on the traits required to be successful, prior to casting the recruiting net.
                                    </p>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={6} md={5} lg={3} className="mb-2">
                            <Card className="p-2 rounded" style={{ textAlign: "center", height: "100%" }}>
                                <div className="text-centre" style={{ color: "#00AFB2" }}>
                                    <h3 style={{ fontSize: "3rem ", fontWeight: "bold" }}>2</h3>
                                    <h3 style={{ fontWeight: "bold" }}>ASSESS</h3>
                                    <h5>Know the candidate</h5>
                                </div>
                                <div style={{ backgroundImage: "linear-gradient(to bottom, white  50%,  #00AFB2  50%)", }} >
                                    <div className="rounded-circle p-1 mx-auto" style={{ backgroundColor: "#00AFB2", width: "fit-content", border: "2px Solid white" }}>
                                        < IoChatbubblesOutline size={40} style={{ fontSize: "2rem", color: "white" }} />
                                    </div>
                                </div>
                                <div className="p-3" style={{ backgroundColor: "#00AFB2", color: "white", height: "100%" }}>
                                    <p>
                                        Data driven candidate insights help managers assess how well candidates fit the job requirements,
                                        and make better informed talent decisions.
                                    </p>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={6} md={5} lg={3} className="mb-2">
                            <Card className="p-2 rounded" style={{ textAlign: "center", height: "100%" }}>
                                <div className="text-centre" style={{ color: "#006581" }}>
                                    <h3 style={{ fontSize: "3rem ", fontWeight: "bold" }}>3</h3>
                                    <h3 style={{ fontWeight: "bold" }}>CHOOSE</h3>
                                    <h5>Know the fit</h5>
                                </div>
                                <div style={{ backgroundImage: "linear-gradient(to bottom, white  50%,  #006581  50%)", }} >
                                    <div className="rounded-circle p-1 mx-auto" style={{ backgroundColor: "#006581", width: "fit-content", border: "2px Solid white" }}>
                                        < TbUserSearch size={40} style={{ fontSize: "2rem", color: "white" }} />
                                    </div>
                                </div>
                                <div className="p-3" style={{ backgroundColor: "#006581", color: "white", height: "100%" }}>
                                    <p>
                                        With our unique Job Fit feature, we help to objectively compare candidates against the chosen benchmark to understand how well
                                        they fit the role requirements.
                                    </p>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={6} md={5} lg={3} className="mb-2">
                            <Card className="p-2 rounded" style={{ textAlign: "center", height: "100%" }}>
                                <div className="text-centre" style={{ color: "#003E53" }}>
                                    <h3 style={{ fontSize: "3rem ", fontWeight: "bold" }}>4</h3>
                                    <h3 style={{ fontWeight: "bold" }}>ENGAGE</h3>
                                    <h5>Know your people</h5>
                                </div>
                                <div style={{ backgroundImage: "linear-gradient(to bottom, white  50%,  #003E53  50%)", }} >
                                    <div className="rounded-circle p-1 mx-auto" style={{ backgroundColor: "#003E53", width: "fit-content", border: "2px Solid white" }}>
                                        < GoPeople size={40} style={{ fontSize: "2rem", color: "white" }} />
                                    </div>
                                </div>
                                <div className="p-3" style={{ backgroundColor: "#003E53", color: "white", height: "100%" }}>
                                    <p>
                                        Our full suite of reports providers a path to employee engagement. Engage your workforce by helping managers onboard,
                                        coach, and develop their employees.
                                    </p>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                </Container>
            </div>
            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#20A7AA" }}>
                    <h1 className="mb-0" style={{ color: "white" }}>
                        Elevate Your Talent Management with Our PXT Select™ Tools!
                    </h1>
                </div>
            </div>
            <div style={{ backgroundColor: "#FAF6F2" }}>
                <Container className="py-4">
                    <Row className="justify-content-center">
                        <Col sm={6} md={6} lg={4} xl={3} className="pb-3">
                            <Card className="p-4 text-center rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                <h3>Pxt Select™</h3>
                                <br />
                                <p>
                                    PXT Select™ helps make the very human decisions about hiring simpler and smarter.
                                    It is an online selection assessment that measures a candidate’s cognitive abilities, behaviors, and interests.
                                </p>

                                <img className="mt-auto" src={pxt_select_service} alt="CheckPoint 360" />
                                <br />
                                <div className="mt-auto">
                                    <a href={pxt_brochure} download={"pxt-brochure.pdf"}>
                                        <Button style={{ backgroundColor: "#20A7AA", color: "white", border: "3px Solid white", width: "100%" }}
                                        >
                                            Download
                                        </Button>
                                    </a>
                                </div>

                            </Card>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={3} className="pb-3">
                            <Card className="p-4 text-center rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                <h3>CheckPoint 360°™</h3>
                                <br />
                                <p>
                                    The CheckPoint 360°™ is a 360-degree feedback tool that helps managers identify and prioritize their own development opportunities.

                                </p>

                                <img className="mt-auto rounded" src={checkPoint_image} alt="CheckPoint 360" />
                                <div className="mt-auto">
                                    <a href={pxt_brochure_cp360} download={"pxt-brochure-cp360.pdf"}>
                                        <Button style={{ backgroundColor: "#20A7AA", color: "white", border: "3px Solid white", width: "100%" }}
                                        >
                                            Download

                                        </Button>
                                    </a>
                                </div>

                            </Card>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={3} className="pb-3">
                            <Card className="p-4 text-center rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                <h3>Customer Service Profile™</h3>
                                <br />
                                <p>
                                    The Customer Service Profile™ is a customer service assessment that measures how well a person fits specific customer service jobs in your organization.
                                </p>

                                <img className="mt-2 rounded" src={customer_service_profile_image} alt="Customer Service Profile" />
                                <br />
                                <div className="mt-auto">
                                    <a href={pxt_brochure_csp_pgp} download={"pxt-brochure-csp.pdf"}>

                                        <Button style={{ backgroundColor: "#20A7AA", color: "white", border: "3px Solid white", width: "100%" }}>
                                            Download

                                        </Button>
                                    </a>
                                </div>
                            </Card>
                        </Col>
                        <Col sm={6} md={6} lg={4} xl={3} className="pb-3">
                            <Card className="p-4 text-center rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                <h3>Step One Survey®</h3>
                                <br />
                                <p>
                                    Employee fraud and theft robs American businesses of over $50 billion annually. It’s startling,
                                    but it’s true. Organisations need help identifying people who are less likely to steal, be dishonest,
                                    and have questionable tendencies.
                                </p>
                                <img className="mt-2 rounded" src={stepOne_survey_image} alt="Step One Survey" />
                                <br />

                                <div>
                                    <a href={pxt_brochure_sos} download={"pxt-brochure-sos.pdf"}>

                                        <Button style={{ backgroundColor: "#20A7AA", color: "white", border: "3px Solid white", width: "100%" }}>
                                            Download


                                        </Button>
                                    </a>
                                </div>
                            </Card>
                        </Col>
                    </Row>
                    <div className="text-center mt-5 mb-4">
                        <h2>
                            Ours Solutions Provide Value & Results
                        </h2>
                    </div>
                    <Row className="text-center">
                        <Col style={{ display: "flex", flexDirection: "column" }} >
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                Engage your people
                            </p>
                            <div className="mt-auto">
                                <FaRegCircleCheck size={40} style={{ color: "#20A7AA", backgroundColor: "#EDFFF2", borderRadius: "50%" }} />
                            </div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }} >
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                Increase productivity
                            </p>
                            <div className="mt-auto">

                                <FaRegCircleCheck size={40} style={{ color: "#20A7AA", backgroundColor: "#EDFFF2", borderRadius: "50%" }} />
                            </div>

                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }} >
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                Reduce hiring bias
                            </p>
                            <div className="mt-auto">
                                <FaRegCircleCheck size={40} style={{ color: "#20A7AA", backgroundColor: "#EDFFF2", borderRadius: "50%" }} />
                            </div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }} >
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                Replicate top performers
                            </p>
                            <div className="mt-auto">
                                <FaRegCircleCheck size={40} style={{ color: "#20A7AA", backgroundColor: "#EDFFF2", borderRadius: "50%" }} />
                            </div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }} >
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                Decrease the cost of bad hires
                            </p>
                            <div className="mt-auto">
                                <FaRegCircleCheck size={40} style={{ color: "#20A7AA", backgroundColor: "#EDFFF2", borderRadius: "50%" }} />
                            </div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }} >
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                Increase employee retention
                            </p>
                            <div className="mt-auto">
                                <FaRegCircleCheck size={40} style={{ color: "#20A7AA", backgroundColor: "#EDFFF2", borderRadius: "50%" }} />
                            </div>
                        </Col>
                        <Col style={{ display: "flex", flexDirection: "column" }}>
                            <p style={{ fontWeight: "bold", fontSize: "14px" }}>
                                Increase employee satisfaction
                            </p>
                            <div className="mt-auto">
                                <FaRegCircleCheck size={40} style={{ color: "#20A7AA", backgroundColor: "#EDFFF2", borderRadius: "50%" }} />
                            </div>
                        </Col>
                    </Row>
                </Container>
            </div>

            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#20A7AA" }}>
                    <h1 className="mb-0" style={{ color: "white" }}>
                        Tailor-made Solutions for Every Requirement
                    </h1>
                </div>

            </div>
            <div style={{ backgroundColor: "#D6E4E2" }}>
                <Container className="py-5 text-right">
                    <Row>
                        <Col sm={12} md={12} lg={8} className="mb-3 mb-sm-0" >
                            <Row>
                                <Col md={4} className="mb-3 mb-sm-0" >
                                    <Card className="p-4 rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                        <Row>
                                            <Col sm={12} md={12} lg={12} xl="auto">
                                                <HiMiniCheckBadge size={40} style={{ color: "#20A7AA" }} />
                                            </Col>
                                            <Col>
                                                <h5>Match The Best Person to the Best Position</h5>
                                            </Col>
                                        </Row>
                                        <ul>
                                            <li>PXT Select®</li>
                                            <li>Customer Service Profile™</li>
                                        </ul>
                                    </Card>
                                </Col>
                                <Col md={4} className="mb-3 mb-sm-0">
                                    <Card className="p-4 rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                        <Row>
                                            <Col sm={12} md={12} lg={12} xl="auto">
                                                <IoIosPeople size={40} style={{ color: "#20A7AA" }} />
                                            </Col>
                                            <Col>
                                                <h5>Increase Employee Retention</h5>
                                            </Col>
                                        </Row>

                                        <ul>
                                            <li>Customer Service Profile™</li>
                                            <li>PXT Select</li>
                                        </ul>

                                    </Card>
                                </Col>
                                <Col md={4} >
                                    <Card className="p-4 rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                        <Row>
                                            <Col sm={12} md={12} lg={12} xl="auto">
                                                <FaBuildingColumns size={30} style={{ color: "#20A7AA" }} />
                                            </Col>
                                            <Col>
                                                <h5>Build Next Generation Leaders</h5>
                                            </Col>
                                        </Row>
                                        <ul>
                                            <li>CheckPoint 360°™</li>
                                            <li>PXT Select</li>
                                            <li>Customer Service Profile™</li>
                                        </ul>
                                    </Card>
                                </Col>
                            </Row>
                            <Row className="mt-3">
                                <Col md={8} className="mb-3 mb-sm-0">
                                    <Card className="p-4 rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                        <Row style={{ height: "100%" }}>
                                            <Col>

                                                <Row>
                                                    <Col sm={12} md={12} lg={12} xl="auto">
                                                        <GiNotebook size={35} style={{ color: "#20A7AA" }} />
                                                    </Col>
                                                    <Col>
                                                        <h5>Strategic Workforce Planning</h5>
                                                    </Col>
                                                </Row>
                                                <ul>
                                                    <li>PXT Select</li>
                                                    <li>Customer Service Profile™</li>
                                                </ul>
                                            </Col>
                                            <Col sm={6}>
                                                <img
                                                    className="rounded "
                                                    style={{ objectFit: "cover", width: "100%", height: "100%" }}
                                                    src={pxt_strategic_workforce_planning} alt="Strategic Workforce Planning" />
                                            </Col>
                                        </Row>

                                    </Card>
                                </Col>
                                <Col md={4}>
                                    <Card className="p-4  rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                        <h5>
                                            <RiComputerLine className="mx-1" size={35} style={{ color: "#20A7AA" }} />
                                            Onboarding Assessments
                                        </h5>


                                        <ul>
                                            <li>PXT Select</li>
                                            <li>Customer Service Profile™</li>
                                            <li>Step One Survey®</li>
                                        </ul>
                                    </Card>
                                </Col>
                            </Row>

                        </Col>
                        <Col sm={12} md={4} lg={4}>
                            <Card className="p-4  rounded" style={{ backgroundColor: "white", color: "black", height: "100%" }}>
                                <h5>
                                    <FaTrophy className="mx-1" size={35} style={{ color: "#20A7AA" }} />
                                    Replicate Top Performers
                                </h5>

                                <ul>
                                    <li>PXT Select</li>
                                    <li>Customer Service Profile™</li>
                                </ul>
                                <img
                                    className="rounded w-100 h-100"
                                    style={{ objectFit: "cover" }}
                                    src={pxt_replicate} alt="Replicate Top Performers" />
                            </Card>
                        </Col>
                    </Row>

                    <ContactUs
                        themeColor="#20A7AA"
                        buttonBackgroundColor="white"
                        textColor="white"
                        buttonColor="#20A7AA"
                    />
                </Container>
            </div>
            <Footer
                themeColor="#20A7AA"
            />
        </div >
    );
}

export default PxtSelectPage;