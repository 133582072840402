import React, { useState } from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import ContactUs from "../../components/contact_us";
import { FaPlayCircle } from "react-icons/fa";
import { Button, Card, Col, Container, Row } from "react-bootstrap";

import ReactPlayer from 'react-player/youtube'

// images
import background_image from "../../assets/five_behaviours/5-b-background.jpg";
import virtualTeamWork from "../../assets/five_behaviours/FiveBehaviors-Closing-Virtual-Teamwork.png";
import cohesiveTeam from "../../assets/five_behaviours/FiveBehaviour-Cohesive-Team.png";
// import hiringTool from "../../assets/gallery/hiring_tools.jpg";
import resultsPyramid from "../../assets/five_behaviours/Container.jpg";

//Documents
import virtual_teamwork from "../../assets/Documents/5b-partner.pdf";
import state_teams from "../../assets/Documents/5b-StateofTeams.pdf";
import keep_your_people from "../../assets/Documents/5b_keepyourpeople.pdf";

import { useNavigate } from "react-router-dom";

export default function FiveBehaviors() {
    const navigate = useNavigate();
    const [playing, setPlaying] = useState(false);


    return (
        <div>
            <Header
                themeColor="#ED223C"
                textColor="#f8f9fa"
            />

            <img src={background_image}
                alt="Background"
                className="img-fluid"
                style={{ objectFit: "cover", height: "100%", width: "100%", maxHeight: "500px", objectPosition: "top" }}
            />
            {/* <Container>
                <Row>
                    <Col className="text-center">
                        <h1 style={{ fontSize: "4em", color: "#ec1b30" }}>Five Behaviors</h1>
                        <p className="heroPageText" style={{ color: "#ec1b30", fontWeight: "bold" }}>
                            The Five Behaviors of a Cohesive Team™ is a unique learning experience that prepares individuals for success in teams.
                        </p>
                    </Col>
                </Row>
            </Container> */}

            {/* <div style={{ backgroundImage: "linear-gradient(to bottom,  #E01B1B, #7A0F0F)", color: "white" }}>
                <Row className="mx-0">
                    <Col md={7} className="px-0">
                        <img src={hiringTool}
                            alt="Hiring Tools"
                            className="img-fluid"
                            style={{ objectFit: "cover", height: "100%", maxHeight: "500px", width: "100%" }}
                        />
                    </Col>
                    <Col md={5} className="p-5">
                        <h2 style={{ fontSize: "4em" }}>About Five Behaviors</h2>
                        <p className="heroPageText">
                            Developed by Patrick Lencioni and utilizing Everything DiSC®, our approach focuses on five key team behaviors:
                            Trust, Conflict, Commitment, Accountability, and Results. Our workshops enhance team dynamics, communication,
                            and collaboration, boosting productivity and effectiveness.
                        </p>
                    </Col>
                </Row>
            </div> */}



            <Container className="">
                <Row className=" mx-0">
                    <Col className="p-4">
                        <Row>
                            <Col md={4}>
                                <h2 style={{ color: "#ED223C" }}>The Five Behaviors Model</h2>
                                <p style={{ fontWeight: "bold" }}>
                                    <span style={{ color: "#ED223C" }}>Trust</span> One Another
                                </p>
                                <p>
                                    When team members are genuinely
                                    transparent and honest with one another, it
                                    forms a safe environment that creates
                                    and builds vulnerability-based trust.
                                </p>
                                <p style={{ fontWeight: "bold" }}>
                                    Engage in  <span style={{ color: "#ED223C" }}>Conflict</span> Around Ideas
                                </p>
                                <p>
                                    With trust, team members are able to engage
                                    in unfiltered, constructive debate of ideas.
                                </p>
                                <p style={{ fontWeight: "bold" }}>
                                    <span style={{ color: "#ED223C" }}>Commit</span> to Decisions
                                </p>
                                <p>
                                    When team members are able to offer
                                    opinions and debate ideas, they will be more
                                    likely to commit to decisions.
                                </p>
                            </Col>
                            <Col md={4}>
                                <img src={resultsPyramid}
                                    alt="Hiring Tools"
                                    className="img-fluid"
                                    style={{ objectFit: "contain", height: "100%", width: "100%" }}
                                />
                            </Col>
                            <Col md={4} >
                                <p style={{ fontWeight: "bold" }}>
                                    Hold One Another <span style={{ color: "#ED223C" }}>Accountable</span>
                                </p>
                                <p>
                                    Once everyone is committed to a clear plan
                                    of action, they will be more willing to hold
                                    one another accountable.
                                </p>

                                <p style={{ fontWeight: "bold" }}>
                                    Focus on Achieving Collective <span style={{ color: "#ED223C" }}>Results</span>
                                </p>
                                <p>
                                    The ultimate goal is the achievement of
                                    collective results, unlocked through implementing the model's
                                    principles of Trust, Conflict, Commitment, and Accountability.
                                </p>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Container>

            <div style={{ padding: "50px 10px", display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                <div className="video-container mx-auto" >

                    <ReactPlayer
                        id="youtube-video"
                        url='https://www.youtube.com/embed/qYRGiM5OICk?si=d5-F10Px6O5VUPV0'
                        playing={playing}
                        controls={false}
                    />


                    <button className="overlay"
                        onClick={() => setPlaying(!playing)}
                        style={{ cursor: "pointer", display: playing ? "none" : "block", border: "none", backgroundColor: "#d3313b" }}
                    >
                        <div className="parent-container" >
                            <h1>
                                Five Behaviors of a Cohesive Team
                            </h1>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <FaPlayCircle className="mx-auto" size={60} style={{ color: "white" }} />
                            </div>
                        </div>
                    </button>
                </div>
            </div>



            <div>
                <div className="text-center py-2" style={{}}>
                    <h1 className="mb-0" style={{ color: "#ED223C" }}>
                        Choose the Right Five Behaviors Solution for Your Team
                    </h1>
                </div>
            </div>
            <Container className="my-4">
                <Row className="text-center justify-content-center">
                    <Col sm={6} md={5} className="mt-2 ">
                        <Card className="p-4 text-center" style={{ border: "5px Solid #d3313b", color: "black", height: "100%" }}>
                            <h2 className="text-center" style={{ color: "#d3313b", fontWeight: "bold" }}>Personal Development</h2>
                            <br />
                            <h5>Build a Culture of Team Work</h5>
                            <br />
                            <p style={{}}>
                                Helps individuals to better understand themselves and their team through the principles of
                                The Five Behaviours.
                            </p>
                            <hr />

                            <h5>Benefits</h5>

                            <p>
                                Individuals Learn How to “Team” Effectively
                                <br />
                                Team and Culture Transformation
                                <br />
                                Employee Development at All Levels
                            </p>
                            <hr />

                            <h5>For Who?</h5>
                            <p>
                                Anyone...
                            </p>
                            <hr />

                            <h5>Number of People</h5>
                            <p>
                                Up to 30...
                            </p>
                            <hr />

                            <h5>Longevity Of Term</h5>
                            <p>
                                No Requirements...
                            </p>
                            <br />
                            <div className="mt-auto">

                                <Button size="lg" variant="primary" style={{ backgroundColor: "#d3313b", color: "white", width: "100%" }}
                                    onClick={() => navigate("/contact-us")}

                                >Learn More</Button>
                            </div>

                        </Card>
                    </Col>
                    <Col sm={6} md={5} className="mt-2">
                        <Card className=" p-4 text-center" style={{ border: "5px Solid #d3313b", color: "black", height: "100%" }}>
                            <h2 className="text-center" style={{ color: "#d3313b", fontWeight: "bold" }}>Team Development</h2>
                            <br />

                            <h5>Develop a Cohesive Intact Team</h5>
                            <br />

                            <p>
                                Helps participants better understand themselves, the  personalities on their team, and how they can effectively
                                work together
                            </p>
                            <hr />

                            <h5>Benefits</h5>

                            <p>
                                Help Intact Teams to Gain Skill to Work Together
                                <br />
                                Effectively Improve Team
                                <br />
                                Productivity Addresses Team Dysfunction
                            </p>
                            <hr />

                            <h5>For Who?</h5>
                            <p>
                                Intact Teams...
                            </p>
                            <hr />

                            <h5>Number of People</h5>
                            <p>
                                5 - 12...
                            </p>
                            <hr />

                            <h5>Longevity Of Term</h5>
                            <p>
                                Designed for teams with a 6-week minimum tenure
                            </p>
                            <br />
                            <div className="mt-auto">

                                <Button size="lg" variant="primary" style={{ backgroundColor: "#d3313b", color: "white", width: "100%" }}
                                    onClick={() => navigate("/contact-us")}
                                >Learn More</Button>
                            </div>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <div>
                <div className="text-center py-2 mt-3" style={{}}>
                    <h1 className="mb-0" style={{ color: "#ED223C" }}>
                        Resources - eBooks and Whitepapers
                    </h1>
                </div>

            </div>
            <Container className="mt-2">
                <Row className="text-center justify-content-center">
                    <Col md={4} style={{ height: "100%" }}>
                        <Card className="mt-3">

                            <img src={virtualTeamWork} alt="Virtual Teamwork" className="img-fluid rounded mb-4" />

                            <div className="p-4">

                                <h4>Closing the virtual Teamwork Skills Gap</h4>
                                <p>
                                    Master the essential skills for effective teamwork in virtual and hybrid workplaces to ensure
                                    your organization thrives in today's evolving workplace. Learn how to create a collaborative culture now!
                                </p>
                                <br />


                                <div className="mt-auto">
                                    <a href={virtual_teamwork} download={"5b-virtual-teamwork-brochure.pdf"}>
                                        <Button size="lg" variant="primary" style={{ backgroundColor: "#ED223C", color: "white", border: "3px Solid white", width: "100%" }}>Download</Button>
                                    </a>
                                </div>
                            </div>

                        </Card>

                    </Col>
                    <Col md={4}>
                        <Card className="mt-3">
                            <img src={cohesiveTeam} alt="Cohesive Team" className="img-fluid rounded mb-4" />

                            <div className="p-4">

                                <h4>Five Behaviors of a Cohesive Team</h4>
                                <p>
                                    Today, organisations emphasize teamwork more than ever, with over 60% of employees dedicating their time to engaging with teams.
                                    This shift has been complicated by factors such as cross-functionality and remote work, particularly in the post-COVID-19 era. Wiley Workplace Learning Solutions conducted a survey to explore how these evolving team dynamics are shaping organisational responses.
                                </p>
                                <a href={state_teams} download={"5b-state-teams-brochure.pdf"}>
                                    <Button size="lg" variant="primary" style={{ backgroundColor: "#ED223C", color: "white", border: "3px Solid white", width: "100%" }}>Download</Button>
                                </a>
                            </div>
                        </Card>
                    </Col>
                    <Col md={4}>
                        <Card className="mt-3">
                            <img src={cohesiveTeam} alt="Cohesive Team" className="img-fluid rounded mb-4" />

                            <div className="p-4">

                                <h4>
                                    Keep Your People: How to Navigate The Great Resignation
                                </h4>
                                <p>
                                    The Great Resignation is a phenomenon that has seen millions of workers quitting their jobs in search of better opportunities. This eBook explores the reasons behind this trend and offers strategies for retaining your best talent.
                                </p>
                                <a href={keep_your_people} download={"5b-keep-your-people.pdf"}>
                                    <Button size="lg" variant="primary" style={{ backgroundColor: "#ED223C", color: "white", border: "3px Solid white", width: "100%" }}>Download</Button>
                                </a>
                            </div>
                        </Card>
                    </Col>
                </Row>

                <ContactUs
                    themeColor="#d3313b"
                    textColor="#f8f9fa"
                    buttonBackgroundColor="#f8f9fa"
                    buttonColor="#d3313b"
                />
            </Container>



            <Footer
                themeColor="#d3313b"
                textColor="#f8f9fa"
            />

        </div >
    );
}