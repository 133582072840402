import { React, useState, useRef } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Container, Form, Card, Row, Col, Button, Modal } from "react-bootstrap";
import { FaRegCircleCheck } from "react-icons/fa6";
import { MdOutlineEmail } from "react-icons/md";
import { PiBuildingOffice } from "react-icons/pi";
import emailjs from "@emailjs/browser";



const ContactUsPage = () => {

    const [validated, setValidated] = useState(false);
    const [loading, setLoading] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const formRef = useRef();

    const onSubmit = (e) => {
        e.preventDefault();
        setLoading(true);

        const form = e.currentTarget;

        if (form.checkValidity() === false) {
            e.stopPropagation();
            setLoading(false);
            return;
        }
        setValidated(true);

        emailjs.sendForm('service_0lt56k9', 'template_5qz0rfp', formRef.current, {
            publicKey: 'OhiYcg2fVG-DhRsEK',
        }).then((result) => {
            setLoading(false);
            setShowPopup(true);
            // setTimeout(() => {
            //     setShowPopup(false);
            // });
        }
        ).catch((error) => {
            setLoading(false);
            console.error('Failed to send email. Error: ', error);
        });
    }

    return (
        <div>
            <Header
                themeColor="#013451"
                textColor="#f8f9fa"
                buttonBackgroundColor="#007bff"
                buttonColor="#f8f9fa"
            />
            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#013451" }}>
                    <h1 className="mb-0" style={{ color: "white" }}>
                        Contact Us
                    </h1>
                </div>
            </div>
            <Container className=" mb-5 pt-5 text-center"  >
                <Form ref={formRef} noValidate onSubmit={onSubmit} validated={validated} >
                    <Card className="mx-auto p-4" style={{ maxWidth: "500px" }}>
                        <Row className="mb-3">
                            <h3 className="mx-auto">Message</h3>
                            <Col sm={12} className="mb-3">
                                <Form.Group >
                                    <Form.Control name="client_name" required type="name" placeholder="Full Name" />
                                </Form.Group>
                            </Col>
                            <Col sm={7} className="mb-3"  >
                                <Form.Group>

                                    <Form.Control name="client_email" required type="email" placeholder="Email" />
                                </Form.Group>
                            </Col>
                            <Col sm={5} className="mb-3">
                                <Form.Group>
                                    <Form.Control name="phone_number" type="tel" placeholder="Phone Number" />
                                </Form.Group>
                            </Col>
                            <div className="mb-3">
                                <Form.Group>
                                    <Form.Control name="company" type="text" placeholder="Company" />
                                </Form.Group>
                            </div>
                            <div className="mb-3">
                                <Form.Select name="option" >
                                    <option>Select service</option>
                                    <option value="PXT Select">PXT Select</option>
                                    <option value="Everything Disc">Everything Disc</option>
                                    <option value="Five Behaviours">Five Behaviours</option>
                                    <option value="Genos">Genos</option>
                                    <option value="Other">Other</option>
                                </Form.Select>
                            </div>


                            <Form.Group>

                                <Form.Control name="message" as="textarea" rows={8} placeholder="Message" />
                            </Form.Group>
                        </Row>
                        <Row>
                            {loading ? <div className="mx-auto">Loading...</div> :
                                <Button type="submit" size="lg" className=" mt-0" style={{ backgroundColor: "#013451" }}>
                                    Send
                                </Button>
                            }
                        </Row>
                        <Modal show={showPopup} onHide={() => setShowPopup(false)} centered
                        >
                            <Modal.Body className="text-center"

                            >
                                <FaRegCircleCheck size={50} style={{ color: "green" }} />
                                <h5>Sent</h5>
                            </Modal.Body>
                        </Modal>
                    </Card>
                </Form>


                <div className="mx-auto" style={{ maxWidth: "800px" }}>
                    <Row className="mt-5" style={{ textAlign: "left" }}>
                        <Col sm={6} className="mb-3" >
                            <h4 className="mb-0">
                                <MdOutlineEmail size={30} className="mx-2" />
                                Email Us
                            </h4>
                            <a href="mailto:info@growingpeoplegreat.com" style={{ color: "black" }}>info@growingpeoplegreat.com</a>
                        </Col>
                        <Col className="mb-3">
                            <Row>
                                <h4 className="mb-0">
                                    <PiBuildingOffice size={30} className="mx-2" />
                                    Offices</h4>
                                <Col sm={12} md={6}>
                                    <address>
                                        76 Emolweni Road,<br />
                                        Kloof, 3610<br /> Durban,
                                        South Africa
                                        <br />

                                        Phone: +27 31 944 3060

                                    </address>
                                </Col>
                                <Col sm={12} md={6}>
                                    <address>
                                        4 Cheyney Road,<br />
                                        Lone Hill Sandton, 2062,
                                        <br />South Africa
                                        <br />
                                        Phone: +27 10 447 1958
                                    </address>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </div>
            </Container >
            <Footer
                themeColor="#013451"
                textColor="#f8f9fa"
                buttonBackgroundColor="#007bff"
                buttonColor="#f8f9fa"
            />
        </div >
    );
}

export default ContactUsPage;