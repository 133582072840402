import React from "react";
import { useNavigate } from "react-router-dom";
import { Row, Card, Button, Container } from "react-bootstrap";

function ContactUs(props) {
    const navigate = useNavigate();

    return (
        <Container>
            <Row className="align-items-center my-5 text-center">
                <Card className="align-items-center mx-auto text-center py-5" style={{ backgroundColor: props.themeColor, maxWidth: "700px" }}>
                    <Card.Body style={{ color: props.textColor }}>
                        <h1 style={{ color: "white" }}>Get Started</h1>
                        <Card.Title>Ready to take your organisation to the next level?</Card.Title>
                        <Card.Text>
                            Contact us today to learn more about our products and services.
                        </Card.Text>
                        <Button
                            onClick={() => navigate("/contact-us")}
                            size="lg" className=" mt-2 contactUsBtnHomePage"
                            style={{ backgroundColor: props.buttonBackgroundColor, color: props.buttonColor }}
                        >
                            Contact Us
                        </Button>
                    </Card.Body>
                </Card>
            </Row>
        </Container>
    );
}

export default ContactUs;
