import React from "react";
import Header from "../../components/header";
import Footer from "../../components/footer";
import { Row, Col, Container, Card, Button } from "react-bootstrap";
import ContactUs from "../../components/contact_us";

//import images
import GenosAssessmentBackground from "../../assets/genos/assessments/genos_assessment_background.jpg"
import GenosAssessmentLeadership from "../../assets/genos/assessments/genos_assessment_leadership.png"
import GenosAssessmentSales from "../../assets/genos/assessments/genos_assessment_sales.png"
import GenosAssessmentJobs from "../../assets/genos/assessments/genos_assessment_job.png"
import GenosAssessmentWorkplace from "../../assets/genos/assessments/genos_assessment_workplace.png"


//Documents
import GenosWorkplaceSelfAssessment from "../../assets/Documents/Genos_Workplace_EI_Self_Report.pdf"
import GenosSalesReport from "../../assets/Documents/Genos_Sales_EI_360_Report.pdf"
import GenosLeadershipReport from "../../assets/Documents/Genos_EI_Leadership_360_Report.pdf"
import Genos_EI_Selection_Report from "../../assets/Documents/Genos_EI_Selection_Report.pdf"

// Icons
import { MdOutlinePerson3, MdPeopleOutline, MdOutlineAttachMoney } from "react-icons/md";
import { IoBriefcaseOutline } from "react-icons/io5";
import { FaRegCircleCheck } from "react-icons/fa6";



export default function GenosAssessmentPage() {
    return (
        <div>
            <Header themeColor="rgba(255, 110, 18, 0.81)"
            />

            <div>
                <Row className="mx-0" >
                    <Col md={6}>
                        <div className="p-5">

                            <h1 className="py-2" style={{ color: "#FF6E12", fontWeight: "bold", letterSpacing: "3px", fontSize: "2.7em" }}>
                                Emotional Intelligence Assessments
                            </h1>
                            <br />
                            <p className="heroPageText">
                                Genos emotional intelligence assessments offer a science-based advantage for individuals and teams.
                                They measure workplace behaviours against an award-winning model of emotional intelligence to boost
                                performance and culture. Genos assessments are used by organisations worldwide and offered in over
                                28 languages.
                            </p>
                            <Button style={{ backgroundColor: "#FF6E12" }} size="lg" variant="primary" target="_blank">
                                Contact Us
                            </Button>
                        </div>

                    </Col>
                    <Col md={6} className="px-0">
                        <img src={GenosAssessmentBackground} alt="Genos Assessment Background" style={{ width: "100%", height: "100%", objectFit: "cover" }} />
                    </Col>
                </Row>
            </div>

            <div>
                <div className="text-center pt-4">
                    <h1 className="mb-0" style={{ fontSize: "2em", color: "#FF6E12" }}>
                        Emotional Intelligence Workplace - Leadership and Sales Assessments
                    </h1>
                </div>
            </div>

            <Container className="py-5">
                <Row className="justify-content-center">
                    <Col md={3} className="text-center mt-2">
                        <Card className="p-3" style={{ backgroundColor: "#209cd7", color: "white" }}>
                            <MdOutlinePerson3 size={50} className="mx-auto" />
                            <h5 className="text-center">
                                Leadership Assessments
                            </h5>
                            <p className="text-center">
                                Front line mangers to CEOs
                            </p>
                        </Card>
                    </Col>
                    <Col md={3} className="text-center mt-2">
                        <Card className="p-3" style={{ backgroundColor: "#f8c74b", color: "white", height: "100%" }}>
                            <MdPeopleOutline size={50} className="mx-auto" />
                            <h5 className="text-center">
                                Workplace Assessments
                            </h5>
                            <p className="text-center">
                                Individuals or teams in any role
                            </p>
                        </Card>
                    </Col>
                    <Col md={3} className="text-center mt-2">
                        <Card className="p-3" style={{ backgroundColor: "#fb1f0d", color: "white", height: "100%" }}>
                            <MdOutlineAttachMoney size={50} className="mx-auto" />
                            <h5 className="text-center">
                                Sales Assessments
                            </h5>
                            <p className="text-center mt-auto">
                                Sales professionals and teams
                            </p>
                        </Card>
                    </Col>
                    <Col md={3} className="text-center mt-2">
                        <Card className="p-3" style={{ backgroundColor: "#702c89", color: "white", height: "100%" }}>
                            <IoBriefcaseOutline size={50} className="mx-auto" />
                            <h5 className="text-center">
                                Selection Assessments
                            </h5>
                            <p className="text-center mt-auto">
                                Job candidates
                            </p>
                        </Card>
                    </Col>
                </Row>

                <hr className="my-5" style={{ borderTop: "2px solid #FF6E12" }} />
                <h3 className="text-center" style={{ color: "#FF6E12" }}>
                    Why Genos Assessments?
                </h3>
                <br />
                <Row className="justify-content-center">
                    <Col md={3} className="text-center">
                        <p>
                            Choose between simple self-assessments or complex multi-level assessments.
                        </p>
                        <FaRegCircleCheck size={30} style={{ color: "#FF6E12", backgroundColor: "white", borderRadius: "50%" }} />
                    </Col>
                    <Col md={3} className="text-center">
                        <p>
                            Measure EI against both global benchmarks, and internal expectations.
                        </p>
                        <FaRegCircleCheck size={30} style={{ color: "#FF6E12", backgroundColor: "white", borderRadius: "50%" }} />
                    </Col>
                    <Col md={3} className="text-center" style={{ display: "flex", flexDirection: "column" }}>
                        <p>
                            Reports available for individuals or an entire team.
                        </p>
                        <div className="mt-auto">
                            <FaRegCircleCheck size={30} style={{ color: "#FF6E12", backgroundColor: "white", borderRadius: "50%" }} />
                        </div>
                    </Col>
                    <Col md={3} className="text-center">
                        <p>
                            Access to interactive digital report and development tools.
                        </p>
                        <FaRegCircleCheck size={30} style={{ color: "#FF6E12", backgroundColor: "white", borderRadius: "50%" }} />
                    </Col>
                    <Col md={3} className="text-center" style={{ display: "flex", flexDirection: "column" }}>
                        <p>
                            Easily embed into existing leadership programs.
                        </p>
                        <div className="mt-auto">
                            <FaRegCircleCheck size={30} style={{ color: "#FF6E12", backgroundColor: "white", borderRadius: "50%" }} />
                        </div>
                    </Col>
                    <Col md={3} className="text-center" style={{ display: "flex", flexDirection: "column" }}>
                        <p>
                            Seamlessly integrate into LMS or other employee management software via API.
                        </p>
                        <div className="mt-auto">
                            <FaRegCircleCheck size={30} style={{ color: "#FF6E12", backgroundColor: "white", borderRadius: "50%" }} />
                        </div>
                    </Col>
                    <Col md={3} className="text-center" style={{ display: "flex", flexDirection: "column" }}>
                        <p>
                            Available in 28 languages.
                        </p>
                        <div className="mt-auto">
                            <FaRegCircleCheck size={30} style={{ color: "#FF6E12", backgroundColor: "white", borderRadius: "50%" }} />
                        </div>
                    </Col>
                </Row>
            </Container>

            <Container className="py-5">
                <Row className="justify-content-center" >
                    <Col md={5} className="text-center" >
                        <div>
                            <h5 className="text-center">
                                Emotional Intelligence Workplace Assessments -
                                Individuals in any role
                            </h5>
                            <br />
                            <img src={GenosAssessmentWorkplace} alt="Genos Assessment Workplace" className="img-fluid" />
                        </div>
                    </Col>

                    <Col md={5}>
                        <Card className="p-4 text-center">
                            <Card.Title>
                                Genos Workplace Self-Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    An individual’s feedback on the importance of emotional intelligence behaviours and an evaluation of their own.
                                </p>
                            </Card.Body>
                            <Card.Title>
                                Genos Workplace 180° Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    Feedback on the individual’s demonstrated emotional intelligence behaviours by a selection of colleagues.
                                </p>
                            </Card.Body>
                            <Card.Title>
                                Genos Workplace 360° Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    Feedback on demonstrated emotional intelligence behaviours from a group of colleagues categorised by manager, peer, and other.
                                </p>
                            </Card.Body>
                            <a href={GenosWorkplaceSelfAssessment} download={"genos_workplace_report.pdf"}>

                                <Button style={{ backgroundColor: "#FF6E12", width: "100%" }} size="lg" variant="primary">Download</Button>

                            </a>

                        </Card>
                        {/* <div className="mt-3 px-4 d-flex justify-content-between">
                            <Button style={{ backgroundColor: "#FF6E12" }} size="lg" variant="primary" href="https://www.genosinternational.com/" target="_blank">Get Started</Button>
                            <Button style={{ backgroundColor: "#FF6E12" }} size="lg" variant="primary" href="https://www.genosinternational.com/" target="_blank">Download</Button>
                        </div> */}
                    </Col>

                </Row>
                <Row className="mt-5 justify-content-center">
                    <Col md={5} className="text-center">
                        <h5 className="text-center">
                            Emotional Intelligence Sales Assessments -  professionals and teams
                        </h5>
                        <br />
                        <img src={GenosAssessmentLeadership} alt="Genos Assessment Leadership" className="img-fluid" />
                    </Col>
                    <Col md={5}>
                        <Card className="p-4 text-center">
                            <Card.Title>
                                Genos Leadership Self-Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    A leader’s feedback on the importance of emotional intelligence behaviours and an evaluation of their own.
                                </p>
                            </Card.Body>
                            <Card.Title>
                                Genos Leadership 180° Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    Feedback on the leader’s demonstrated emotional intelligence behaviours by a selection of colleagues.
                                </p>
                            </Card.Body>
                            <Card.Title>
                                Genos Leadership 360° Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    Feedback on a leader’s demonstrated emotional intelligence behaviours from a group of colleagues categorised
                                    by manager, peer, direct reports and other.
                                </p>
                            </Card.Body>
                            <a href={GenosLeadershipReport} download={"genos_leadership_report.pdf"}>
                                <Button style={{ backgroundColor: "#FF6E12", width: "100%" }} size="lg" variant="primary">Download</Button>
                            </a>

                        </Card>
                        {/* <div className="mt-3 px-4 d-flex justify-content-between">
                            <Button style={{ backgroundColor: "#FF6E12" }} size="lg" variant="primary" href="https://www.genosinternational.com/" target="_blank">Get Started</Button>
                            <Button style={{ backgroundColor: "#FF6E12" }} size="lg" variant="primary" href="https://www.genosinternational.com/" target="_blank">Download</Button>
                        </div> */}
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-center">
                    <Col md={5} className="text-center">
                        <h5 className="text-center">
                            Emotional Intelligence Sales Assessments
                            Sales professionals and teams
                        </h5>
                        <br />
                        <img src={GenosAssessmentSales} alt="Genos Assessment Sales" className="img-fluid" />
                    </Col>
                    <Col md={5}>
                        <Card className="p-4 text-center">
                            <Card.Title>
                                Genos Sales Behaviour Self-Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    How well an individual believes they demonstrate emotionally intelligent sales behaviour, and how important they
                                    believe it is to do so.
                                </p>
                            </Card.Body>
                            <Card.Title>
                                Genos Sales Behaviour 180° Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    Feedback on the individual’s demonstrated emotional intelligence sales behaviours by a selection of
                                    colleagues and/or clients.
                                </p>
                            </Card.Body>
                            <Card.Title>
                                Genos Behaviour 360° Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    Feedback on an individual’s demonstrated emotional intelligence sales behaviours from a group of colleagues
                                    categorised by manager, peers and customers.
                                </p>
                            </Card.Body>
                            <a href={GenosSalesReport} download={"genos_sales_report.pdf"}>
                                <Button style={{ backgroundColor: "#FF6E12", width: "100%" }} size="lg" variant="primary">Download</Button>
                            </a>

                        </Card>
                    </Col>
                </Row>
                <Row className="mt-5 justify-content-center">
                    <Col md={5} className="text-center">
                        <h5 className="text-center">
                            Emotional Intelligence Selection Assessment
                            For job candidates
                        </h5>

                        <img src={GenosAssessmentJobs} alt="Genos Assessment Job" className="img-fluid" />
                    </Col>
                    <Col md={5}>
                        <Card className="p-4 text-center">
                            <Card.Title>
                                Genos Selection Assessment
                            </Card.Title>
                            <Card.Body>
                                <p>
                                    The best measure of how often a candidate demonstrates emotional intelligence in the workplace. Provides an intuitive
                                    interview guide designed to assist in hiring for emotional intelligence.
                                </p>
                            </Card.Body>
                            <a href={Genos_EI_Selection_Report} download={"genos_selection_report.pdf"}>
                                <Button style={{ backgroundColor: "#FF6E12", width: "100%" }} size="lg" variant="primary">Download</Button>
                            </a>
                        </Card>
                    </Col>
                </Row>


            </Container>

            <Container>
                <ContactUs
                    themeColor="#FF6E12"
                    textColor="#f8f9fa"
                    buttonBackgroundColor="white"
                    buttonColor="#FF6E12"
                />
            </Container>

            <Footer themeColor="#FF6E12" />
        </div >
    )
}