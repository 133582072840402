import React, { useState } from "react";
import Header from "../components/header";
import Footer from "../components/footer";
import { Row, Col, Container, Carousel } from "react-bootstrap";

//CSS
import "../styles/home_page.css"
import ServicesCard from "../components/services_card";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { FaPlayCircle } from "react-icons/fa";
import { ImEarth } from "react-icons/im";


import background_image from "../assets/home_page/background.jpeg"

// Logo
import pxtSelect_logo from "../assets/logos/pxtSelect_logo.png";
import disc_logo from "../assets/logos/everythingDisc_logo.png";
import fiveBehaviors_logo from "../assets/logos/fiveBehaviors_logo.png";
import genos_logo from "../assets/logos/genos_logo.png";

import coaching_image from "../assets/gallery/training_photo.jpg";
import team_work_image from "../assets/gallery/team_work.jpg";
import hiring_tools_image from "../assets/gallery/hiring_tools.jpg";
import consulting_image from "../assets/gallery/consulting.jpg";

import SliderHeroPage from "../components/slider_hero_page";

//Slider
import Slider from "react-slick";

import { useSpring, animated } from "@react-spring/web";
import ContactUs from "../components/contact_us";

import ReactPlayer from 'react-player/youtube'


function Number({ number }) {
    const { numberValue } = useSpring({
        from: { numberValue: 0 },
        numberValue: number,
        delay: 200,
        config: {
            duration: 2000
        }
    });

    return <animated.span>{numberValue.to((n) => n.toFixed(0))}</animated.span>;
}


// const data = [
//     {
//         name: "John Doe",
//         title: "CEO, Company XYZ",
//         testimonial: "I am so happy with the results of the training. I have seen a significant improvement in my team's communication and collaboration skills.",
//         photo: "https://via.placeholder.com/80",
//         rating: 5
//     },
//     {
//         name: "Samu Mkhize",
//         title: "HR Manager, Company ABC",
//         testimonial: "The training was very informative and engaging. I would recommend Growing People Great to any organization looking to improve their team dynamics.",
//         photo: "https://via.placeholder.com/80",
//         rating: 4

//     },
//     {
//         name: "Rodrick Duma",
//         title: "CEO, Company XYZ",
//         testimonial: "I am so happy with the results of the training. I have seen a significant improvement in my team's communication and collaboration skills.",
//         photo: "https://via.placeholder.com/80",
//         rating: 3
//     },
//     {
//         name: "Keke Phahsa",
//         title: "HR Manager, Company ABC",
//         testimonial: "The training was very informative and engaging. I would recommend Growing People Great to any organization looking to improve their team dynamics.",
//         photo: "https://via.placeholder.com/80",
//         rating: 2

//     }

// ]



function HomePage() {

    const logoBanner = {
        dots: false,
        infinite: true,
        slidesToShow: 4,
        autoplay: true,
        speed: 12000,
        autoplaySpeed: 0,
        cssEase: "linear",
        pauseOnHover: false,
        arrows: false,
        responsive: [
            {
                breakpoint: 1305,
                settings: {
                    slidesToShow: 3,
                    autoplaySpeed: 0,
                    speed: 12000,
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 1,
                    dots: false,
                    autoplaySpeed: 0,
                    speed: 16000,
                }
            }

        ]
    }

    // const settings = {
    //     dots: true,
    //     infinite: true,
    //     slidesToShow: 4,
    //     slidesToScroll: 1,
    //     autoplay: true,
    //     speed: 12500,
    //     autoplaySpeed: 0,
    //     cssEase: "linear",
    //     pauseOnHover: true,
    //     arrows: false,

    //     responsive: [
    //         {
    //             breakpoint: 990,
    //             settings: {
    //                 slidesToShow: 2,
    //                 slidesToScroll: 1,
    //                 dots: false,
    //                 autoplaySpeed: 2000,
    //                 speed: 12000,
    //             }
    //         },
    //         {
    //             breakpoint: 600,
    //             settings: {
    //                 slidesToShow: 1,
    //                 slidesToScroll: 1,
    //                 dots: false,
    //                 autoplaySpeed: 2500,
    //                 speed: 12000,
    //             }
    //         }

    //     ]
    // };

    const [playing, setPlaying] = useState(false);

    return (
        <div>
            <Header
                themeColor="#0C97D0"
            />
            <Container>
                <Row>
                    <Col sm={12} md={6}>
                        <img src={background_image} alt="PXT Select" style={{ width: "100%", maxHeight: "600px", objectFit: "contain", height: "100%" }} />

                    </Col>
                    <Col sm={12} md={6}>
                        <Carousel controls={false} touch={true} indicators={true} variant="dark">
                            <Carousel.Item>
                                <SliderHeroPage
                                    title="World-Class Coaching Service"
                                    description="Genos Emotional Intelligence services will help you motivate and manage your employees using personalised feedback reports, to enhance work-place productivity."
                                    subtitle="Ready to Empower Your People for Greatness?"
                                    image={coaching_image}
                                    link="/services/genos"

                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <SliderHeroPage

                                    title="Build the Best Leaders and Teams"
                                    description="Our 5 Behaviors inspired workshops enhance team dynamics, collaboration and leadership effectiveness."
                                    subtitle="Ready to Develop Highly Effective Teams and Leaders?"
                                    image={team_work_image}
                                    themeColor="#ED223C"
                                    link="/services/five-behaviors"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <SliderHeroPage
                                    title="Top-Tier Hiring Selection Tools"
                                    description="Elevate your company with smarter hiring selection, better interviews and retain longer with PXT Select and Checkpoint tools. "
                                    subtitle="Ready to Hire and Build Your Dream Team?"
                                    image={hiring_tools_image}
                                    themeColor="#013451"
                                    link="/services/pxt-select"
                                />
                            </Carousel.Item>
                            <Carousel.Item>
                                <SliderHeroPage
                                    title="Expert Consulting & Data-Driven Assessments"
                                    description="Everything DiSC offers top personality assessment tools to help employees understand and improve their behavior, enhancing workplace relationships and productivity."
                                    subtitle="Ready to Enhance Your Team's Communication and Collaboration?"
                                    image={consulting_image}
                                    themeColor="#013451"
                                    link="/services/everything-disc"
                                />
                            </Carousel.Item>
                        </Carousel>
                    </Col>
                </Row>
            </Container>


            {/* <Carousel fade controls={false} touch={true}>
                <Carousel.Item>
                    <div style={{ backgroundImage: "linear-gradient(to bottom, #666666, #000000)", color: "white" }}>
                        <SliderHeroPage
                            title="World-Class Coaching Service"
                            description="Genos Emotional Intelligence services will help you motivate and manage your employees using personalized feedback reports, to enhance work-place productivity."
                            subtitle="Ready to Empower Your People for Greatness?"
                            image={coaching_image}
                            themeColor="#013451"
                            link="/services/genos"

                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{ backgroundImage: "linear-gradient(to bottom, #E01B1B, #7A0F0F)", color: "white" }}>
                        <SliderHeroPage

                            title="Build the Best Leaders and Teams"
                            description="Our 5 Behaviors inspired workshops enhance team dynamics, collaboration and leadership effectiveness."
                            subtitle="Ready to Develop Highly Effective Teams and Leaders?"
                            image={team_work_image}
                            themeColor="#ED223C"
                            link="/services/five-behaviors"
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{ backgroundImage: "linear-gradient(to bottom, #00AFB2, #004B4C)", color: "white" }}>
                        <SliderHeroPage
                            title="Top-Tier Hiring Selection Tools"
                            description="Elevate your company with smarter hiring selection, better interviews and retain longer with PXT Select and Checkpoint tools. "
                            subtitle="Ready to Hire and Build Your Dream Team?"
                            image={hiring_tools_image}
                            themeColor="#013451"
                            link="/services/pxt-select"
                        />
                    </div>
                </Carousel.Item>
                <Carousel.Item>
                    <div style={{ backgroundImage: "linear-gradient(to bottom, #2EA3F2, #1B5E8C)", color: "white" }}>
                        <SliderHeroPage
                            title="Expert Consulting & Data-Driven Assessments"
                            description="Everything DiSC offers top personality assessment tools to help employees understand and improve their behavior, enhancing workplace relationships and productivity."
                            subtitle="Ready to Enhance Your Team's Communication and Collaboration?"
                            image={consulting_image}
                            themeColor="#013451"
                            link="/services/everything-disc"
                        />
                    </div>
                </Carousel.Item>
            </Carousel> */}





            <div className="experience">
                <p className="mb-0">
                    Over <span style={{ color: "#013451", fontWeight: "bold" }} > 30 YEARS</span>  experience of top-tier training, with trainers ready to assist you.
                </p>
            </div>

            <Container className="py-5 text-center ">


                <div className="video-container mx-auto mb-2" >

                    <ReactPlayer
                        id="youtube-video"
                        url='https://www.youtube.com/embed/qtuehIPnnBc?si=qUG7ZZccz-HN1K2H&enablejsapi=1'
                        playing={playing}
                        controls={false}
                    />


                    <button className="overlay"
                        onClick={() => setPlaying(!playing)}
                        style={{ cursor: "pointer", display: playing ? "none" : "block", border: "none", backgroundColor: "#0C97D0" }}
                    >
                        <div className="parent-container" >
                            <h1>
                                WHAT WE DO
                            </h1>
                            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                                <FaPlayCircle className="mx-auto" size={60} style={{ color: "white" }} />
                            </div>
                        </div>
                    </button>
                </div>

                <Row className="mt-2 text-center justify-content-center">
                    <Col md="auto">
                        <ImEarth size={200} style={{ color: "#073d56" }} />

                    </Col>
                    <Col md="auto">
                        <div className="text-start">
                            <h2 style={{ color: "#00B67A", fontWeight: "bold", fontSize: "3em" }}>
                                <Number number={7000} />
                                +

                            </h2>
                            <p style={{ fontWeight: "bold" }}>Individuals we’ve successfully trained</p>
                            <h2 style={{ color: "#F90E0E", fontWeight: "bold", fontSize: "3em" }}>
                                <Number number={20} />
                                +
                            </h2>
                            <p style={{ fontWeight: "bold" }}>Countries trained</p>
                        </div>
                        {/* <Card className=" p-4 stats rounded mb-2">
                            <h2>
                                <Number number={7000} />
                                +

                            </h2>
                            <p>Individuals we’ve successfully trained</p>
                            <h2>
                                <Number number={20} />
                                +</h2>
                            <p>Countries trained</p>
                        </Card> */}
                    </Col>

                </Row>
            </Container>
            <h3 className="text-center"
                style={{ color: "#0C97D0", fontWeight: "bold" }}
            >
                Our Partners</h3>
            <div className="logoBanner mb-5" >
                <Slider {...logoBanner}>
                    <img src={pxtSelect_logo} alt="PXT Select" />
                    <img src={disc_logo} alt="Everything DiSC" />
                    <img src={fiveBehaviors_logo} alt="Five Behaviors" />
                    <img src={genos_logo} alt="Genos" />
                </Slider>
            </div>

            {/* <h1>Products & Services we offer</h1> */}
            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#0C97D0" }}>
                    <h1 className="mb-0" style={{ color: "white" }}>
                        Products & Services we offer
                    </h1>
                </div>
            </div>
            <div style={{ backgroundColor: "#FAFAFA" }} >
                <Container >
                    <Row className="align-items-center py-5">
                        <Col xs={{ order: 2 }} sm={{ span: 6, order: 2 }}>
                            <ServicesCard
                                title="PXT, PXT Select, PSA, Checkpoint"
                                description="Discover powerful talent management and hiring assessment tools crafted by Wiley, meticulously designed to empower your organisation with smarter, more informed hiring decisions."
                                themeColor="#00AFB2"
                                link="/services/pxt-select"
                            />
                        </Col>
                        <Col xs={{ order: 1 }} md={{ order: 2 }} className="mb-5 mb-md-0" >
                            <img className="service_offered_images" src={pxtSelect_logo} alt="PXT Select" />
                        </Col>
                    </Row>
                    <Row className="align-items-center mb-5">
                        <Col className="mb-5 mb-md-0">
                            <img className="service_offered_images" src={disc_logo} alt="Everything DiSC" />
                        </Col>
                        <Col sm={6}>
                            <ServicesCard
                                title="Everything DiSC - Culture Catalyst"
                                description="Using  DiSC assessments, our certified trainers lead workshops aimed at enhancing your team's communication, teamwork, leadership, and conflict resolution skills.."
                                themeColor="#0094D0"
                                link="/services/everything-disc"
                            />
                        </Col>
                    </Row>
                    <Row className="align-items-center  ">
                        <Col xs={{ order: 2 }} sm={{ span: 6, order: 2 }} className="mb-5 mb-sm-1">
                            <ServicesCard
                                title="Five Behaviors"
                                description="Developed by Patrick Lencioni and utilizing Everything DiSC®, our approach focuses on five key team behaviors: Trust, Conflict, Commitment, Accountability, and Results. Our workshops enhance team dynamics, communication, and collaboration, boosting productivity and effectiveness."
                                themeColor="#E01B1B"
                                link="/services/five-behaviors"
                            />
                        </Col>
                        <Col xs={{ order: 1 }} md={{ order: 2 }} className="mb-5 mb-md-0">
                            <img className="service_offered_images" src={fiveBehaviors_logo} alt="Five Behaviors" />
                        </Col>
                    </Row>
                    <Row className="align-items-center pb-5">
                        <Col className="mb-5 mb-md-0">
                            <img className="service_offered_images" src={genos_logo} alt="Genos" />
                        </Col>
                        <Col sm={6}>
                            <ServicesCard
                                title="Genos Emotional Intelligence"
                                description="Dive into the world of Emotional Intelligence (EI) with our comprehensive services! We specialize in Genos EI assessments, personalised feedback reports, and engaging workshops that elevate EI for individuals and organisational teams."
                                themeColor="#232525"
                                link="/services/genos"
                            />
                        </Col>
                    </Row>
                </Container>
            </div>
            {/* <h1 className="" >What our clients say about us</h1> */}
            <div>
                <div className="text-center py-2" style={{ backgroundColor: "#0C97D0" }}>
                    <h1 className="mb-0" style={{ color: "white" }}>
                        What our clients say about us
                    </h1>
                </div>
            </div>
            {/* <div className="testimonial mt-5">
                <Slider {...settings}>
                    {
                        data.map((item, index) => {
                            return (
                                <Card key={index} className="px-0 pt-0 rounded" >
                                    <Card.Body>

                                        <Card.Text>

                                            {item.testimonial}
                                        </Card.Text>

                                        <Card.Title>{item.name}</Card.Title>
                                        <Card.Subtitle>{item.title}</Card.Subtitle>
                                    </Card.Body>
                                </Card>
                            )
                        })
                    }
                </Slider>
            </div> */}
            <Container>

                <ContactUs
                    // themeColor="#4aadd7"
                    themeColor="#0C97D0"
                    textColor="white"
                    buttonBackgroundColor="white"
                    buttonColor="#0C97D0"
                // buttonColor="#013451"
                />
            </Container >
            <Footer
                themeColor="#0C97D0"
            />
        </div >
    );
}

export default HomePage;